import { IconButton, Pill, Tooltip } from "@darktrace/ui-components";
import React, { useState } from "react";
import styled from "styled-components";
import { AssignRolesModal } from "./AssignRolesModal.jsx";

const StyledRoles = styled.div`
  display: flex;
  gap: 0.8rem;
`;

export function Roles({ userId, email, name, roles, childRoles, parentRoles, clientId, isYou, isUserFromParentClient }) {
  const [isAssignRolesModalOpen, setIsAssignRolesModalOpen] = useState(false);
  const user = { id: userId, email, name, roles, childRoles, parentRoles, clientId };

  const rolesToShow = isUserFromParentClient ? childRoles : roles;

  return (
    <StyledRoles>
      <Tooltip text={t(`Assign ActiveAI Security Portal Roles`)}>
        <IconButton
          className="plus-btn"
          size="small"
          icon="plus"
          variant="secondary"
          onClick={() => setIsAssignRolesModalOpen((prev) => !prev)}
        />
      </Tooltip>

      <div className="roles">
        {rolesToShow.map((role, index) => (
          <Pill key={index}>{role.name}</Pill>
        ))}
      </div>

      {isAssignRolesModalOpen && (
        <AssignRolesModal user={user} isYou={isYou} open={isAssignRolesModalOpen} onClose={() => setIsAssignRolesModalOpen(false)} />
      )}
    </StyledRoles>
  );
}
