import { Card } from "@darktrace/ui-components";
import React from "react";
import styled from "styled-components";

const StyledFiltersCard = styled(Card)`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;

  > .dt-ui-card__contents {
    display: none;
  }

  > .dt-ui-card__title {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &::after {
      width: 100%;
    }
  }

  /* > .dt-ui-card__contents-wrapper {
    border-bottom: 1px solid transparent;
    transition: 400ms ease;
  } */

  /* &.dt-ui-card--expanded > .dt-ui-card__contents-wrapper {
    border-color: var(--dt-ui-card-border);
  } */
`;

// Note: this was originally used for User Filters
// these have now been moved to the Left Hand panel
// but we're planning to use the Advanced Filters component here later

export function UsersFilters() {
  return <StyledFiltersCard title={t(`User Management`)}></StyledFiltersCard>;
}
