import React, { useState } from "react";
import styled from "styled-components";
import { GhostElement, ModalCard, Section, Tabs } from "@darktrace/ui-components";
import { usePTNAlert, usePTNAlertsAIASummary } from "../../../../logic/api";
import { useActiveClientId } from "../../../../logic/hooks";

const StyledModalCard = styled(ModalCard)`
  .dt-ui-modal-card {
    width: 50vw;
    min-width: 45rem;
    display: flex;
    flex-direction: column;
    height: 60vh;
    .dt-ui-card__contents {
      .dt-ui-ghost-element {
        height: 100%;
      }
      .client-details {
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 1.6rem;
        span {
          color: var(--dt-ui-tabs-section-title-text-color);
        }
      }
      .content {
        padding: 1.2rem 0;
        pre {
          white-space: break-spaces;
        }
      }
      .title {
        font-size: 1rem;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.2rem;
        letter-spacing: 0.15rem;
        margin: 1.2rem 0;
      }
      .filters {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.2rem;
        padding-bottom: 1.2rem;
      }
      .dt-ui-chat__messages {
        .dt-ui-chat__bubble {
          background-color: var(--dt-ui-button-secondary-active-bg);
          width: 100%;
          max-width: 100%;
        }
      }
      .bold {
        font-weight: 700;
      }
      .line {
        margin: 0.4rem 0;
        display: block;
        line-height: 2rem;
      }
    }
  }
`;

function Components({ alert, isLoading, isError }) {
  if (isLoading) return <GhostElement />;
  if (isError) return <div>{t(`Failed to load components.`)}</div>;
  if (!alert.triggered_components?.length) return <div>{t(`No components to show.`)}</div>;
  return (
    <div>
      <p className="title">{t(`Triggered components:`)}</p>
      <div>
        {alert.triggered_components.map(({ metric_name, metric_filters }) => {
          return (
            <span key={metric_name}>
              <p>
                <span className="bold"> {t(`Metric Name: `)}</span> <span>{metric_name}</span>
              </p>
              {Object.entries(metric_filters).map(([key, value], i) => {
                return (
                  <span key={i} className="line">
                    <span className="bold">{t(`Filter[{0}]: `, i + 1)}</span>
                    <span>{key + " = " + value}</span>
                  </span>
                );
              })}
            </span>
          );
        })}
      </div>
    </div>
  );
}

function AIASummary({ alertId, activeClientId }) {
  const { data: aiaSummary, isError, isLoading } = usePTNAlertsAIASummary({ alertId, clientId: activeClientId });

  if (isLoading) return <GhostElement />;
  if (isError) return <div>{t(`Failed to load AIA summary.`)}</div>;
  // Is plain text
  return <pre>{aiaSummary}</pre>;
}

export function ALertDataModal({ onClose = () => {}, open, alertId, ...attrs }) {
  const modalRoot = document.querySelector("#modal-root");
  const activeClientId = useActiveClientId();
  const { data: alert, isLoading, isError } = usePTNAlert({ alertId, clientId: activeClientId });

  const [activeTabId, setActiveTabId] = useState("alert-data");

  function handleClose() {
    onClose();
  }

  return (
    <StyledModalCard
      modalRoot={modalRoot}
      onClose={handleClose}
      open={open}
      title={
        <Tabs
          tabs={[
            { id: "alert-data", title: t(`Alert Data`), icon: "warning" },
            { id: "aia-summary", title: t(`AIA Summary`), icon: "microchip-ai" },
          ]}
          activeTabId={activeTabId}
          onSelectTab={(tabId) => setActiveTabId(tabId)}
        />
      }
      {...attrs}
    >
      {alert ? (
        <>
          <p className="client-details">
            <span>#{alert.pbid || ""}</span> {alert.client?.codename || ""}
          </p>
          <Section />
        </>
      ) : null}
      <div className="content">
        {activeTabId === "aia-summary" ? (
          <AIASummary {...{ alertId, activeClientId }} />
        ) : (
          <Components {...{ alert, isLoading, isError }} />
        )}
      </div>
    </StyledModalCard>
  );
}
