import React, { useState } from "react";
import { useQueryParams } from "../../logic/hooks";
import { Input, useDebouncedEffect } from "@darktrace/ui-components";

export function UserManagementSearch({ onSearchValueChange }) {
  const { queryParams, updateQueryParams } = useQueryParams();
  const { search } = queryParams;

  const [searchValue, setSearchValue] = useState(search);

  useDebouncedEffect(
    () => {
      updateQueryParams({ search: searchValue });
      onSearchValueChange(searchValue);
    },
    500,
    [searchValue],
  );

  return (
    <Input
      className="users-search"
      type="search"
      placeholder={t(`Search by email`) + "..."}
      value={searchValue}
      onChange={setSearchValue}
    />
  );
}
