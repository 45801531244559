// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  /* variables */
  --header-height: 6rem;

  /* colors */
  --main-orange: #f18415;
}

body.dt-ui-polar-light {
  --separator-color: var(--dt-ui-polar-snow-grey-800);
  --header-bg: var(--dt-ui-polar-snow-grey-600);
  --ghost-element-bg: rgba(0, 0, 0, 0.05);
}

body.dt-ui-polar-dark {
  --separator-color: var(--dt-ui-polar-dark-ice-300);
  --header-bg: var(--dt-ui-polar-dark-ice-600);
  --ghost-element-bg: rgba(255, 255, 255, 0.05);
}
`, "",{"version":3,"sources":["webpack://./src/styles/variables.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,qBAAqB;;EAErB,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,mDAAmD;EACnD,6CAA6C;EAC7C,uCAAuC;AACzC;;AAEA;EACE,kDAAkD;EAClD,4CAA4C;EAC5C,6CAA6C;AAC/C","sourcesContent":[":root {\n  /* variables */\n  --header-height: 6rem;\n\n  /* colors */\n  --main-orange: #f18415;\n}\n\nbody.dt-ui-polar-light {\n  --separator-color: var(--dt-ui-polar-snow-grey-800);\n  --header-bg: var(--dt-ui-polar-snow-grey-600);\n  --ghost-element-bg: rgba(0, 0, 0, 0.05);\n}\n\nbody.dt-ui-polar-dark {\n  --separator-color: var(--dt-ui-polar-dark-ice-300);\n  --header-bg: var(--dt-ui-polar-dark-ice-600);\n  --ghost-element-bg: rgba(255, 255, 255, 0.05);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
