import { useReducer } from "react";

const initialState = [];

export function useEditUsersReducer(users) {
  function reducer(state, action) {
    switch (action.type) {
      case "update-user": {
        const newState = [...state];
        const originalUser = users.find((user) => user.id === action.id);
        const indexOfUserToUpdate = state.findIndex((user) => user.id === action.id);
        const userToUpdate = indexOfUserToUpdate === -1 ? undefined : state[indexOfUserToUpdate];
        if (userToUpdate) {
          // make update to user
          const updatedUser = { ...userToUpdate, [action.field]: action.value };
          // if updated field is same as initial, set field to undefined
          if (Array.isArray(originalUser[action.field]) && Array.isArray(updatedUser[action.field])) {
            const sameLength = updatedUser[action.field].length === originalUser[action.field].length;
            const sameItems = updatedUser[action.field].every((item) => originalUser[action.field].includes(item));
            if (sameLength && sameItems) updatedUser[action.field] = undefined;
          } else {
            if (updatedUser[action.field] === originalUser[action.field]) updatedUser[action.field] = undefined;
          }
          // update state with updated user
          newState.splice(indexOfUserToUpdate, 1, updatedUser);
          return newState;
        } else {
          // if updated field is same as initial, end
          if (action.value === originalUser[action.field]) return state;
          const updatedUser = { id: action.id, [action.field]: action.value };
          newState.push(updatedUser);
          return newState;
        }
      }
      case "reset-users":
        return [];
    }
  }
  return useReducer(reducer, initialState);
}
