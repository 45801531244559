import React, { useEffect, useState } from "react";
import { Button, Checkbox, Input, ModalCard, Tooltip } from "@darktrace/ui-components";
import styled from "styled-components";
import { resetPassword } from "../../logic/api.js";
import { useDispatch } from "react-redux";
import { newToast } from "../../logic/store.js";

const StyledModalCard = styled(ModalCard)`
  .dt-ui-modal-card {
    min-height: unset;
    min-width: 28rem;

    .dt-ui-card__contents {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1.2rem;

      .checkbox {
        display: flex;
        justify-content: flex-end;
      }

      .fields {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        .field {
          display: flex;
          width: 100%;
          gap: 0.8rem;
          align-items: center;
        }
      }

      .actions {
        display: flex;
        gap: 1.2rem;
        justify-content: flex-end;
      }
    }
  }
`;

const StyledInput = styled(Input)`
  width: 18rem;
`;

export function PasswordChangeModal({ open, onClose = () => {} }) {
  const modalRoot = document.querySelector("#modal-root");

  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [oldPassword, setOldPassword] = useState();
  const [verifying, setVerifying] = useState(false);
  const [passwordError, setPasswordError] = useState();

  const inputType = showPassword ? "text" : "password";
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{9,64}$/;

  const validateField = (field, saving) => {
    if (!verifying && !saving) {
      return null;
    }
    switch (field) {
      case "old":
        return !!oldPassword?.length && !passwordError;
      case "new":
        return pattern.test(newPassword);
      case "confirm":
        return confirmPassword && confirmPassword === newPassword;
    }
  };

  const validateForm = () => ["old", "new", "confirm"].every((field) => validateField(field, true));

  useEffect(() => {
    setVerifying(false);
    setPasswordError(null);
  }, [newPassword, oldPassword]);

  useEffect(() => {
    setConfirmPassword("");
    setNewPassword("");
    setOldPassword("");
    setShowPassword(false);
  }, [open]);

  const handleSavePassword = () => {
    setVerifying(true);
    if (validateForm()) {
      resetPassword({ oldPassword, newPassword })
        .then(() => {
          dispatch(newToast({ variant: "success", title: t(`Password reset succesfully`) }));
          onClose();
          setPasswordError(false);
        })
        .catch(({ response }) => {
          dispatch(newToast({ variant: "error", title: t(`Unable to reset password`) }));
          if (response.status === 400) {
            setPasswordError(t(`Incorrect old password`));
          }
        });
    }
  };

  return (
    <StyledModalCard title={t(`Change Password`)} open={open} onClose={onClose} modalRoot={modalRoot}>
      <form className="fields" onSubmit={(e) => e.stopPropagation()}>
        <input hidden type="text" autoComplete="username" />
        <div className="field">
          <StyledInput
            key="old"
            inputAttrs={{ autoFocus: true, autoComplete: "current-password" }}
            value={oldPassword}
            valid={validateField("old")}
            invalidMsg={passwordError ?? t(`Old password is required`) + "."}
            onChange={setOldPassword}
            type={inputType}
            placeholder={t(`Old Password`)}
          />
        </div>

        <div className="field">
          <StyledInput
            key="new"
            inputAttrs={{ autoComplete: "new-password" }}
            value={newPassword}
            valid={validateField("new")}
            invalidMsg={t(`Invalid Password`)}
            onChange={setNewPassword}
            type={inputType}
            placeholder={t(`New Password`)}
          />
          <Tooltip
            text={
              <>
                <p>{t(`Please choose a password that meets the following criteria`)}:</p>
                <ul>
                  <li>{t(`Contains at least one lowercase letter`)}</li>
                  <li>{t(`Contains at least one uppercase letter`)}</li>
                  <li>{t(`Contains at least one digit`)}</li>
                  <li>{t(`Must be between 9 and 64 characters in length`)}</li>
                </ul>
              </>
            }
          ></Tooltip>
        </div>

        <div className="field">
          <StyledInput
            key="confirm"
            inputAttrs={{ autoComplete: "new-password" }}
            value={confirmPassword}
            valid={validateField("confirm")}
            invalidMsg={t(`Password doesn't match`)}
            onChange={setConfirmPassword}
            type={inputType}
            placeholder={t(`Confirm New Password`)}
          />
        </div>
      </form>

      <div className="checkbox">
        <Checkbox label={t(`Show Passwords`)} checked={showPassword} onChange={setShowPassword} />
      </div>

      <div className="actions">
        <Button variant="secondary" onClick={onClose}>
          {t(`Cancel`)}
        </Button>
        <Button onClick={handleSavePassword}>{t(`Change Password`)}</Button>
      </div>
    </StyledModalCard>
  );
}
