import React, { useState } from "react";

import { Button, ModalCard, SearchDropdown } from "@darktrace/ui-components";
import styled from "styled-components";

import { useSelector } from "react-redux";
import { UserInfoSubheader } from "./UserInfoSubheader.jsx";
import { useMutliClientUserAccessFlags } from "../../logic/hooks.js";
import { AssignRolesModal } from "./AssignRolesModal.jsx";

const StyledTenantAccessModal = styled(ModalCard)`
  .dt-ui-modal-card {
    overflow: hidden;
    min-width: 50rem;
  }
  .tenants-search {
    width: 100%;
  }

  .dt-ui-card__contents {
    padding: 0;

    .tenants-list {
      padding: 0.8rem 1.6rem;
      display: flex;
      flex-direction: column;
    }
  }
`;

export function TenantAccessModal({ modalRoot, open, onClose, userClients, username, userId, clientId: userClientId, email, isYou }) {
  const clients = useSelector((state) => state.app.clients);
  const defaultClientId = useSelector((state) => state.app.defaultClientId);

  const selectedClientIds = userClients.map((client) => client.id);

  const { data: multiClientUserAccessFlags } = useMutliClientUserAccessFlags();
  const availableUserManagementClientIds = multiClientUserAccessFlags
    .filter(({ flags }) => flags.includes("user-management"))
    .map(({ clientId }) => clientId);

  const formattedClients = clients
    .filter((client) => {
      if (userClientId === defaultClientId) return true;
      else return client.id === userClientId;
    })
    .map((client) => ({
      ...client,
      userHasUserManagementAccessForClient: availableUserManagementClientIds.includes(client.id),
    }))
    .toSorted((clientA, clientB) => {
      if (selectedClientIds.includes(clientA.id)) return -1;
      if (selectedClientIds.includes(clientB.id)) return 1;
      return 0;
    });

  const [searchValue, setSearchValue] = useState("");
  const filteredClients = formattedClients.filter((client) => selectedClientIds.includes(client.id));

  const [selectedClient, setSelectedClient] = useState(null);
  const isTenantAccessModalOpen = open && !selectedClient;
  const isAssignRolesModalOpen = open && selectedClient;

  return (
    <>
      <StyledTenantAccessModal
        keepMounted={false}
        open={isTenantAccessModalOpen}
        onClose={onClose}
        title={`Tenant Access`}
        modalRoot={modalRoot}
        closeOnClickBackdrop={false}
        subheaders={[
          { size: "large", component: <UserInfoSubheader email={email} name={username} isYou={isYou} /> },
          {
            size: "large",
            component: (
              <SearchDropdown
                on
                className="tenants-search"
                placeholder={t(`Search for a tenant...`)}
                searchValue={searchValue}
                items={formattedClients.map((client) => ({
                  id: client.id,
                  searchId: client.label,
                  label: <ClientItem key={client.id} client={client} setSelectedClient={setSelectedClient} />,
                }))}
                onChangeSearch={setSearchValue}
                openWhileInputEmpty
              />
            ),
          },
        ]}
      >
        <div className="tenants-list">
          {filteredClients.length === 0 && <span>{t(`User does not have access to any tenants.`)}</span>}
          {filteredClients.map((client) => (
            <ClientItem key={client.id} client={client} setSelectedClient={setSelectedClient} />
          ))}
        </div>
      </StyledTenantAccessModal>

      <AssignRolesModal
        open={isAssignRolesModalOpen}
        user={{ id: userId, name: username, email, clientId: userClientId }}
        isYou={isYou}
        onClose={() => {
          onClose();
          setSelectedClient(null);
        }}
        initialClientId={selectedClient?.id}
      />
    </>
  );
}

const StyledClientItem = styled.div`
  padding-block: 0.8rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
  justify-content: space-between;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid var(--dt-ui-card-border);
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  .client-info {
    display: flex;
    align-items: center;
    gap: 0.6rem;

    .client-name {
      font-size: 1.2rem;
      /* font-weight: 500; */
    }
  }
`;

function ClientItem({ client, setSelectedClient }) {
  return (
    <StyledClientItem className="client-item">
      <div className="client-info">
        <span className="client-name">{client.label}</span>
      </div>

      <Button
        size="small"
        variant="secondary"
        onClick={() => setSelectedClient(client)}
        iconTrailing="arrow-right"
        disabled={!client.userHasUserManagementAccessForClient}
      >
        {t(`Edit Access`)}
      </Button>
    </StyledClientItem>
  );
}
