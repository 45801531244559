import { Button, ModalCard } from "@darktrace/ui-components";
import React from "react";
import styled from "styled-components";
import { useLogout } from "../logic/hooks.js";

const StyledTimeoutOverlay = styled(ModalCard)`
  .content {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    .text {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 0.4rem;
    }

    .buttons {
      align-self: stretch;
      display: flex;
      justify-content: flex-end;
      gap: 0.8rem;
    }
  }
`;

export function TimeoutOverlay({ open }) {
  const logout = useLogout();
  const modalRoot = document.querySelector("#modal-root");

  return (
    <StyledTimeoutOverlay title={t(`Logged out`)} open={open} modalRoot={modalRoot}>
      <div className="content">
        <div className="text">
          <span>{t(`You have been logged out of the server due to inactivity.`)}</span>
          <span>{t(`Click below to log in again.`)}</span>
        </div>

        <div className="buttons">
          <Button onClick={logout}>{t(`Log in`)}</Button>
        </div>
      </div>
    </StyledTimeoutOverlay>
  );
}
