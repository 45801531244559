import React, { useState } from "react";
import styled from "styled-components";
import {
  Accordion,
  Card,
  CollapsableCard,
  Dropdown,
  GhostElement,
  IconButton,
  Section,
  Tile,
  TileContainer,
  Tooltip,
} from "@darktrace/ui-components";
import { ProductTile } from "./ProductTile.jsx";
import { useMyProductAccessInstancesStream, useProductAccessInstances, useUserAccessFlags, useUserProfileInfo } from "../../logic/api.js";
import { useActiveClientId, useQueryParams } from "../../logic/hooks.js";
import { useDispatch, useSelector } from "react-redux";
import { setIsSidepanelCollapsed } from "../../logic/store.js";
import { AssignProductsModal } from "../user-management/AssignProductsModal.jsx";
import { ManageCustomDomainsModal } from "../ManageCustomDomainsModal.jsx";

const StyledDashboard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  gap: 2.4rem;
  max-height: calc(100vh - var(--header-height));

  .title-section {
    background-color: var(--dt-ui-field-bg);
    padding: 2.4rem;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    border-radius: 0.4rem;

    .bold {
      font-weight: 700;
    }

    .name {
      font-size: 2.8rem;
      line-height: 1;
      font-weight: 500;
    }

    .tm {
      vertical-align: super;
      font-size: 1rem;
    }
  }

  .main-section {
    display: flex;
    flex-grow: 1;
    height: 0;
    align-items: stretch;

    .main-section__primary {
      display: flex;
      flex-grow: 1;
      gap: 2rem;
      align-items: stretch;
    }

    .main-section__secondary {
      display: flex;
      flex-direction: column;

      .dt-ui-accordion {
        height: 0;
        flex-grow: 1;
        transition: margin-left 200ms ease-in-out;

        margin-left: 2rem;

        &.dt-ui-accordion--collapsed {
          margin-left: 0;
        }

        .dt-ui-accordion__contents {
          height: 100%;

          .instances-card {
            height: 100%;

            .dt-ui-card__title-icon {
              color: var(--dt-ui-medium);
            }
          }
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      .inner-card {
        width: 36.8rem;

        .tenant-card-contents {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          gap: 0.8rem;

          .dt-ui-dropdown-button:disabled {
            opacity: 100%;
            cursor: default;

            &:after {
              content: none;
            }
          }

          .product-connection-status-tile .dt-ui-tile__header {
            padding-block: 0.4rem;

            .product-connection-status-pill {
              display: flex;
              gap: 0.4rem;
              align-items: center;
              border-radius: 0.4rem;
              background-color: var(--dt-ui-card-bg);
              padding: 0.4rem 0.8rem;

              i {
                color: var(--dt-ui-medium);
              }

              span {
                font-weight: 700;
              }
            }
          }

          .tenant-services-section {
            padding-block: 0.8rem;
          }
        }

        .deployment-tile {
          .dt-ui-tile__header {
            padding: 0.8rem 1.2rem;
          }
        }
      }
    }
  }

  .products-card {
    flex: 1;
    /* height: 0; */

    .dt-ui-card__title {
      padding: 1.2rem;
    }

    .products {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;
      height: 100%;
      justify-content: space-between;

      .unavailable-products-tile {
        .dt-ui-tile__header {
          padding-block: 0.6rem;
        }
        .instance-count {
          background-color: var(--dt-ui-card-container-header-bg);
          padding: 0.4rem 0.8rem;
          border-radius: 0.4rem;
        }
      }

      .product-section {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr));
        gap: 1.6rem;
        overflow: auto;

        .full-span {
          grid-column: span 2;
        }
      }
    }
  }

  .instances-card {
    .dt-ui-card__title {
      gap: 3.6rem;
    }

    .dt-ui-card__title-left {
      white-space: nowrap;
    }

    .instances {
      .instance-statuses {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        .instance-status {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 0.8rem;

          .instance-details {
            display: flex;
            flex-direction: column;
            white-space: nowrap;

            .status-type {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
`;

export function Dashboard() {
  const dispatch = useDispatch();
  const activeClientId = useActiveClientId();
  const _clients = useSelector((state) => state.app.clients);
  const clients = _clients.map((client) => ({ id: client.id, label: client.label, selected: client.id === activeClientId }));
  const defaultClientId = useSelector((state) => state.app.defaultClientId);
  const defaultClientLabel = _clients.find((client) => client.id === defaultClientId)?.label;
  const showTimeoutOverlay = useSelector((state) => state.app.showTimeoutOverlay);
  const { updateQueryParams } = useQueryParams({ defaultQueryParams: { clientId: defaultClientId } });
  const [showProductConnectionStatus, setShowProductConnectionStatus] = useState(false);
  const isSidepanelCollapsed = useSelector((state) => state.app.isSidepanelCollapsed);
  const [isAssignProductsModalOpen, setIsAssignProductsModalOpen] = useState(false);
  const [isManageCustomDomainsModalOpen, setIsManageCustomDomainsModalOpen] = useState(false);

  // queries
  const { data: userAccessFlags = [] } = useUserAccessFlags({ clientId: activeClientId });
  const canUserSeeAllInstances = userAccessFlags.includes("product-access-management");

  const { data: userProfile = {} } = useUserProfileInfo();

  const { data: accessInstances = [] } = useProductAccessInstances({
    clientId: activeClientId,
    enabled: canUserSeeAllInstances,
  });

  const { data: availableInstancesForUser, isLoading: isStreamingProducts } = useMyProductAccessInstancesStream({
    clientId: activeClientId,
    enabled: true,
  });

  const unavailableUpInstancesForUser = !canUserSeeAllInstances
    ? []
    : isStreamingProducts
      ? []
      : accessInstances.filter(
          (instance) =>
            !availableInstancesForUser.some((availableInstance) => availableInstance.instanceId === instance.instanceId) &&
            (instance.state === "UP" || instance.type !== "sabre-threat-visualiser-product-access") &&
            (instance.type !== "customer-portal-product-access" || activeClientId === defaultClientId),
        );

  // computed
  const instancesThatAreDown = canUserSeeAllInstances
    ? accessInstances
        .filter((instance) => instance.state !== "UP")
        .filter((instance) => instance.type === "sabre-threat-visualiser-product-access") // TODO: this should not need to filter by type if API returns a state for all instances
        .map((instance) => ({
          ...instance,
          instanceName: instance.displayName,
          serviceType: instance.type.split("-product-access")[0],
          disabled: true,
        }))
    : [];

  const instancesToShow = [...availableInstancesForUser, ...instancesThatAreDown].reduce((prevInstancesToShow, instance) => {
    if (!prevInstancesToShow.some((prevInstance) => prevInstance.instanceId === instance.instanceId)) prevInstancesToShow.push(instance);
    return prevInstancesToShow;
  }, []);

  const instancesForSelectedDeployment = instancesToShow.map((instance) => ({
    ...instance,
    serviceId: instance.serviceId ?? instance.instanceId, // some instances do not have an associated serviceId
  }));

  const services = [
    "customer-portal-service-type",
    "customer-portal-staging-service-type",
    "dcrs-service-type",
    "dcrs-staging-service-type",
  ];
  const { servicesForSelectedDeployment, productsForSelectedDeployment } = instancesForSelectedDeployment.reduce(
    ({ servicesForSelectedDeployment: prevServices, productsForSelectedDeployment: prevProducts }, currentInstance) => {
      if (!currentInstance.canLogin) return { servicesForSelectedDeployment: prevServices, productsForSelectedDeployment: prevProducts };

      const isCustomerPortal = currentInstance.serviceType === "customer-portal-service-type";
      if (isCustomerPortal && activeClientId !== defaultClientId) {
        return { servicesForSelectedDeployment: prevServices, productsForSelectedDeployment: prevProducts };
      }

      const existingService = prevServices.find((prevService) => prevService.serviceType === currentInstance.serviceType);
      const existingProduct = prevProducts.find((prevProduct) => prevProduct.serviceType === currentInstance.serviceType);

      const existingEntity = existingService ?? existingProduct;
      if (existingEntity) existingEntity.instances.push(currentInstance);
      else {
        const isService = services.includes(currentInstance.serviceType);
        const arrayToPushTo = isService ? prevServices : prevProducts;
        const serviceLabel = currentInstance.serviceName ?? currentInstance.instanceName;
        arrayToPushTo.push({
          serviceId: currentInstance.serviceId,
          serviceLabel,
          serviceType: currentInstance.serviceType,
          instances: [currentInstance],
        });
      }

      return { servicesForSelectedDeployment: prevServices, productsForSelectedDeployment: prevProducts };
    },
    { servicesForSelectedDeployment: [], productsForSelectedDeployment: [] },
  );

  // TODO: remove
  const deployments = [
    // { id: "test-id-1", label: "Deployment 1" },
    // { id: "test-id-2", label: "Deployment 2" },
  ];
  const hasMultipleDeployments = deployments.length > 1;
  const hasMultipleClients = clients.length > 1;
  const canShowProductConnectionStatus = canUserSeeAllInstances && instancesThatAreDown.length > 0;

  return (
    <StyledDashboard>
      <div className="title-section">
        <span className="name" data-test-id="title-text">
          <span className="bold">{t(`Darktrace`)}</span> {t(`ActiveAI Security Portal`)}
          <span className="tm">{t(`TM`)}</span>
        </span>
      </div>

      <div className="main-section">
        <div className="main-section__primary">
          {(canShowProductConnectionStatus || hasMultipleDeployments || hasMultipleClients || servicesForSelectedDeployment.length > 0) && (
            <CollapsableCard
              title={t(`Darktrace ActiveAI Security Portal`)}
              width={"40rem"}
              style={{ flexShrink: "0" }}
              container={"strong"}
              controlled
              collapsed={isSidepanelCollapsed}
              onClickCollapsedToggle={() => dispatch(setIsSidepanelCollapsed(!isSidepanelCollapsed))}
            >
              <div className="content">
                {isSidepanelCollapsed ? (
                  <Tile
                    className="collapsed-filter-tile"
                    icon="buildings"
                    onClick={() => dispatch(setIsSidepanelCollapsed(false))}
                    noColorBar
                  />
                ) : (
                  <Card
                    className="inner-card"
                    title={t(`Tenant`)}
                    icon={"buildings"}
                    titleRight={
                      <>
                        <IconButton icon="cog" variant="tertiary" onClick={() => setIsManageCustomDomainsModalOpen((prev) => !prev)} />
                        <ManageCustomDomainsModal
                          open={isManageCustomDomainsModalOpen}
                          onClose={() => setIsManageCustomDomainsModalOpen(false)}
                        />
                      </>
                    }
                  >
                    <div className="tenant-card-contents">
                      <Dropdown displayValue={defaultClientLabel} disabled />

                      {clients.length > 1 && (
                        <Dropdown
                          label={`${t(`Your Products for`)}:`}
                          items={clients}
                          onSelect={(id) => updateQueryParams({ clientId: id })}
                          hasSearch
                        />
                      )}

                      {canShowProductConnectionStatus && (
                        <Tile
                          className="product-connection-status-tile"
                          noColorBar
                          header={"Product Connection Status"}
                          headerRight={
                            <div className="product-connection-status-pill">
                              <i className="fa fa-triangle-exclamation" />
                              <span>{instancesThatAreDown.length}</span>
                            </div>
                          }
                          onClick={() => setShowProductConnectionStatus((prev) => !prev)}
                        />
                      )}

                      {servicesForSelectedDeployment.length > 0 && (
                        <>
                          <Section className="tenant-services-section" title={t(`Tenant Services`)} />

                          {servicesForSelectedDeployment.map((service) => {
                            return <ProductTile key={service.serviceId} {...service} type="service" />;
                          })}
                        </>
                      )}
                    </div>
                  </Card>
                )}

                {hasMultipleDeployments &&
                  (isSidepanelCollapsed ? (
                    <Tile
                      className="collapsed-filter-tile"
                      icon="boxes-stacked"
                      onClick={() => dispatch(setIsSidepanelCollapsed(false))}
                      noColorBar
                    />
                  ) : (
                    <Card className="inner-card" icon="boxes-stacked" title={t(`Deployment`)}>
                      <TileContainer>
                        <Tile
                          className="deployment-tile"
                          noColorBar
                          header={t(`All Deployments`)}
                          headerRight={<IconButton icon="arrow-right" variant="tertiary" />}
                          active
                          onClick={() => {}}
                        />
                        {deployments.map((deployment) => {
                          // const quickFilterId = `role-${role.id}`;
                          return (
                            <Tile
                              key={deployment.id}
                              className="deployment-tile"
                              noColorBar
                              header={deployment.label}
                              headerRight={<IconButton icon="arrow-right" variant="tertiary" />}
                              onClick={() => {}}
                            />
                          );
                        })}
                      </TileContainer>
                    </Card>
                  ))}
              </div>
            </CollapsableCard>
          )}

          <Card className="products-card" title={"Product List"} container>
            <div className="products">
              <div className="product-section your-products">
                {productsForSelectedDeployment.length > 0 || isStreamingProducts ? (
                  <>
                    {productsForSelectedDeployment.map((product, index) => {
                      return <ProductTile key={index} {...product} />;
                    })}
                    {isStreamingProducts && (
                      <GhostElement style={{ aspectRatio: "5 / 3" }}>
                        <ProductTile />
                      </GhostElement>
                    )}
                  </>
                ) : (
                  !showTimeoutOverlay && (
                    <span className="full-span">
                      {t(`You don't have permission to access any products. Please contact your administrator`)}.
                    </span>
                  )
                )}
              </div>

              {unavailableUpInstancesForUser?.length > 0 && (
                <>
                  <Tile
                    className="unavailable-products-tile"
                    noColorBar
                    icon="users-cog"
                    header={t(`View products that have not been assigned to your user`)}
                    headerRight={<div className="instance-count">{unavailableUpInstancesForUser.length}</div>}
                    onClick={() => setIsAssignProductsModalOpen(true)}
                  />

                  <AssignProductsModal
                    open={isAssignProductsModalOpen}
                    onClose={() => setIsAssignProductsModalOpen(false)}
                    isYou={true}
                    user={userProfile.user}
                  />
                </>
              )}
            </div>
          </Card>
        </div>

        <div className="main-section__secondary">
          {canShowProductConnectionStatus && (
            <Accordion collapsed={!showProductConnectionStatus} horizontal>
              <Card
                className="instances-card"
                icon="triangle-exclamation fa-xl "
                title={"Product Connection Status"}
                container
                titleRight={<IconButton icon="xmark" variant="tertiary" onClick={() => setShowProductConnectionStatus(false)} />}
              >
                <div className="instances">
                  <div className="instance-statuses">
                    {accessInstances.map((instance) => {
                      return (
                        <div className="instance-status" key={instance.instanceId}>
                          <div className="instance-details">
                            <span className="instance-name">{instance.displayName}:</span>
                            <span className="instance-status-info">
                              {t(`Status`)} <span className="status-type">{instance.state ?? t(`Unknown`)}</span>
                            </span>
                          </div>

                          <Tooltip placement="left" text={t(`This appliance is currently unavailable.`)} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Card>
            </Accordion>
          )}
        </div>
      </div>

      {!isStreamingProducts && clients.length > 1 && (
        <span>
          {t(`Note`)}:{" "}
          {availableInstancesForUser.length > 0
            ? t(`You may be able to access additional Darktrace products in the other`)
            : t(`You may be able to access Darktrace products in the other`)}{" "}
          {clients.length === 2 ? t(`tenant you have access to`) : t(`tenants you have access to`)}.
        </span>
      )}
    </StyledDashboard>
  );
}
