import React, { useState } from "react";
import styled from "styled-components";
import { Button, Dropdown, Input, ModalCard, Tag } from "@darktrace/ui-components";
import { assignTag, deleteTag, usePTNAlertTags } from "../../../../logic/api";
import { useDispatch } from "react-redux";
import { newToast } from "../../../../logic/store";
import { useActiveClientId } from "../../../../logic/hooks";

const StyledModalCard = styled(ModalCard)`
  .dt-ui-modal-card {
    display: flex;
    flex-direction: column;
    width: 40vw;
    max-width: 35rem;

    .dt-ui-card__contents {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1.6rem;
      max-height: 30vh;

      .top {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        .grid {
          display: grid;
          align-items: center;
          column-gap: 1rem;
          row-gap: 0.4rem;
          grid-template-columns: auto 1fr;
        }
        .new-tag {
          padding-top: 0.8rem;
          span {
            display: flex;
            gap: 0.8rem;
            align-items: center;
            .dt-ui-input__wrapper {
              flex-grow: 1;
            }
          }
          p {
            text-transform: uppercase;
            font-weight: 600;
            color: var(--dt-ui-card-title-text-color);
            font-size: 1rem;
            padding-bottom: 0.4rem;
          }
        }
        .selected-tags {
          display: flex;
          flex-wrap: wrap;
          gap: 0.8rem;
        }
      }

      .action-buttons {
        display: flex;
        gap: 0.8rem;
        button {
          flex-grow: 1;
        }
      }
    }
  }
`;

export function TagsModal({ onClose = () => {}, open, existingTags = [], alertId, ...attrs }) {
  const activeClientId = useActiveClientId();
  const dispatch = useDispatch();

  const modalRoot = document.querySelector("#modal-root");

  const [selectedTags, setSelectedTags] = useState(existingTags);
  const { data: availableTags } = usePTNAlertTags(activeClientId);
  const formattedDropdownItems = availableTags?.map((item) => ({
    ...item,
    selected: selectedTags.some((t) => t.id === item.id),
  }));

  const [showAddNewTag, setShowAddNewTag] = useState(false);
  const [newTagValue, setNewTagValue] = useState("");
  const placeholder = t(`Select Tags`);
  const isSaveDisabled =
    !selectedTags.length ||
    (selectedTags.length === existingTags.length && selectedTags.every((x) => existingTags.find((y) => y.id === x.id)));

  function handleClose() {
    onClose();
  }

  function handleSave() {
    if (!selectedTags.length) return;
    const tagsToAssign = selectedTags.filter((tag) => !existingTags.find((existing) => existing.id === tag.id));
    const tagsToDelete = existingTags.filter((tag) => !selectedTags.find((existing) => existing.id === tag.id));
    tagsToAssign.forEach((tag) => {
      assignTag({ alertId, clientId: activeClientId, tag });
    });
    tagsToDelete.forEach((tag) => {
      deleteTag({ alertId, clientId: activeClientId, tag });
    });
    handleClose();
  }

  function handleAddNewTag() {
    if (!newTagValue) return;
    dispatch(newToast({ variant: "error", title: t(`Unable to create new tag`) })); // TO DO
  }

  return (
    <StyledModalCard modalRoot={modalRoot} open={open} title={t(`Tags`)} {...attrs}>
      <div className="top">
        <div className="grid">
          <Dropdown
            displayValue={selectedTags.length ? t(`{0} Tags Selected`, selectedTags.length) : placeholder}
            placeholder={placeholder}
            items={formattedDropdownItems}
            onSelect={(id) => {
              if (selectedTags.some((tag) => tag.id === id)) return;
              setSelectedTags((prev) => [...prev, formattedDropdownItems.find((x) => x.id === id)]);
            }}
          />
          <Button variant="tertiary" icon="plus far" onClick={() => setShowAddNewTag(true)}>
            {t(`Add New Tag`)}
          </Button>
        </div>
        {showAddNewTag ? (
          <div className="new-tag">
            <p>{t(`Add new tag`)}</p>
            <span>
              <Input placeholder={t(`Type here...`)} value={newTagValue} onChange={(val) => setNewTagValue(val)} />
              <Button variant="tertiary" disabled={!newTagValue} onClick={handleAddNewTag}>
                {t(`Confirm`)}
              </Button>
            </span>
          </div>
        ) : null}
        <div className="selected-tags">
          {selectedTags.map(({ display_name, label, id }) => (
            <Tag
              key={id}
              text={display_name || label}
              actionIcon="times"
              onActionClick={() => setSelectedTags((prev) => prev.filter((tag) => tag.id !== id))}
            />
          ))}
        </div>
      </div>

      <div className="action-buttons">
        <Button variant="secondary" onClick={handleClose}>
          {t(`Cancel`)}
        </Button>
        <Button disabled={isSaveDisabled} onClick={handleSave}>
          {t(`Save`)}
        </Button>
      </div>
    </StyledModalCard>
  );
}
