import { Button, ButtonGroup, ModalCard, Section, TileContainer } from "@darktrace/ui-components";
import React, { useState } from "react";
import styled from "styled-components";
import { AnalystTile } from "../../shared/AnalystTile.jsx";
import { useActiveClientId } from "../../../../logic/hooks.js";
import { assignUser, useUserProfileInfo } from "../../../../logic/api.js";

const StyledModalCard = styled(ModalCard)`
  .dt-ui-card__contents {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    .controls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .dt-ui-section {
        flex-grow: 1;
        margin-right: 1.2rem;
      }
    }
    .dt-ui-tile-container {
      padding: 0;
      height: 30rem;
      overflow: scroll;
    }
    .buttons {
      display: flex;
      gap: 0.8rem;
      button {
        flex-grow: 1;
      }
    }
  }
`;

export function AssignModal({ alertId, open, onClose = () => {}, assigneeId = null }) {
  const modalRoot = document.querySelector("#modal-root");
  const activeClientId = useActiveClientId();
  const { data: currentUser } = useUserProfileInfo();
  const [selectedUser, setSelectedUser] = useState(assigneeId);
  // TO DO dynamic
  const time = "2h 15min";
  // TO DO endpoint
  const analysts = [];

  function handleAssign() {
    assignUser({ alertId: alertId, clientId: activeClientId, userId: selectedUser });
    onClose();
  }

  return (
    <StyledModalCard modalRoot={modalRoot} open={open} onClose={onClose} title={t(`Assign #`) + alertId} icon="user-shield">
      <AnalystTile
        name={currentUser.user.name}
        active={currentUser?.user?.id === selectedUser}
        onClick={() => setSelectedUser(currentUser?.user?.id)}
        disabled={assigneeId === currentUser?.user?.id}
      />
      <span className="controls">
        <Section title={t(`SOC Analysts`)} />
        <ButtonGroup
          buttons={[
            { text: t(`Online Now`), id: "online" },
            { text: t(`Next {0}`, time), id: "next", disabled: true },
          ]}
        />
      </span>
      <TileContainer>
        {analysts.length ? (
          analysts.map(({ id, fullName }) => {
            return (
              <AnalystTile
                key={id}
                name={fullName}
                active={id === selectedUser}
                onClick={() => setSelectedUser(id)}
                disabled={assigneeId === id}
              />
            );
          })
        ) : (
          <div>{t(`No analysts to show.`)}</div>
        )}
      </TileContainer>
      <span className="buttons">
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button disabled={!selectedUser || selectedUser === assigneeId} onClick={handleAssign}>
          Assign
        </Button>
      </span>
    </StyledModalCard>
  );
}
