import React from "react";
import styled from "styled-components";

const StyledUsername = styled.div`
  display: flex;
  flex-direction: column;

  .name {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    line-height: 1;
    white-space: nowrap;
    font-weight: 600;
  }

  .dt-ui-tag__button-text {
    padding: 0.2rem 0.4rem;
    font-weight: 700;
  }

  .email {
  }

  .you {
    font-weight: 800;
    font-style: italic;
  }
`;

export function Username({ username, email, isYou }) {
  return (
    <StyledUsername>
      <div className="name">
        <span>{username}</span>
        {isYou && <span className="you">(you)</span>}
      </div>
      {email && (
        <div className="email">
          <span>{email}</span>
        </div>
      )}
    </StyledUsername>
  );
}
