import { ButtonGroup, useColorTheme } from "@darktrace/ui-components";
import React from "react";

export function ColorThemeToggle() {
  const [colorTheme, setColorTheme] = useColorTheme();

  return (
    <ButtonGroup
      activeButtonId={colorTheme}
      buttons={[
        { id: "polar-light", icon: "sun", tooltip: t(`Light Mode`) },
        { id: "polar-dark", icon: "moon", tooltip: t(`Dark Mode`) },
      ]}
      onClick={() => {
        const newColorTheme = colorTheme === "polar-light" ? "polar-dark" : "polar-light";
        setColorTheme(newColorTheme);
      }}
    />
  );
}
