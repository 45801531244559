import { useNavigate } from "react-router";
import { logoutUser, fetchUserClients, verifyUser, _useClientUsers, _useParentClientUsers, fetchUserAccessFlags } from "./api.js";
import { useDispatch, useSelector } from "react-redux";
import { setIsUserVerified, setClients, setDefaultClientId, setShowTimeoutOverlay } from "./store.js";
import { useSearchParams, useLocation } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import { applicationPages } from "./util.js";
import { useDebouncedEffect } from "@darktrace/ui-components";
import { useQueries } from "@tanstack/react-query";

export function useLogout() {
  const dispatch = useDispatch();

  return async () => {
    let location = "/#/login";

    try {
      const response = await logoutUser();

      dispatch(setIsUserVerified(false));
      dispatch(setShowTimeoutOverlay(false));

      if (response.status === 200 && response.data.location) {
        location = response.data.location;
      }

      return true;
    } catch {
      return false;
    } finally {
      window.location.href = location;
    }
  };
}

export function useInitialiseApp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  function initialiseApp() {
    // verify user is logged in
    verifyUser()
      .then(() => {
        dispatch(setIsUserVerified(true));
        if (location.pathname === "/") navigate("/dashboard");

        // fetch clients and update in redux
        fetchUserClients().then((availableClients) => {
          const clients = availableClients?.map((client) => ({
            id: client.id,
            label: client.name,
            isDarktrace: client.is_darktrace,
          }));
          const defaultClientId = availableClients?.find((client) => client.default)?.id ?? clients?.[0]?.id;

          dispatch(setClients(clients));
          dispatch(setDefaultClientId(defaultClientId));
        });
      })
      .catch(() => {
        dispatch(setIsUserVerified(false));
        if (applicationPages.some((page) => page.id === location.pathname)) navigate("/login");
      });
  }

  return initialiseApp;
}

export function useActiveClientId() {
  const defaultClientId = useSelector((state) => state.app.defaultClientId);
  const { queryParams } = useQueryParams({ defaultQueryParams: { clientId: defaultClientId } });

  return queryParams["clientId"];
}

export function useQueryParams({ defaultQueryParams = {}, hideDefaultQueryParams = true } = {}) {
  const [queryParams, setSearchParams] = useSearchParams();

  function updateQueryParams(changes = {}) {
    const updatedSearchParams = new URLSearchParams(queryParams);
    Object.entries(changes).forEach(([key, value]) => {
      if (defaultQueryParams[key] === true && value === false) updatedSearchParams.delete(key);
      if (value === "" || value === undefined || value === null) updatedSearchParams.delete(key);
      else if (hideDefaultQueryParams && defaultQueryParams[key] === value) updatedSearchParams.delete(key);
      else updatedSearchParams.set(key, value);
    });

    setSearchParams(updatedSearchParams, { replace: true });
  }

  // add defaultQueryParams to url (if hideDefaultQueryParams is false)

  const queryParamsObj = useMemo(() => {
    const paramsObj = {};
    if (hideDefaultQueryParams) {
      Object.entries(defaultQueryParams).forEach(
        ([key, defaultValue]) => (paramsObj[key] = defaultValue === "false" ? false : defaultValue),
      );
    }
    queryParams.forEach((value, key) => (paramsObj[key] = value === "false" ? false : value));
    return paramsObj;
  }, [queryParams, defaultQueryParams]);

  useDebouncedEffect(
    () => {
      const paramsObj = {};
      queryParams.forEach((value, key) => (paramsObj[key] = value));

      const newQueryParams = { ...defaultQueryParams, ...paramsObj };
      const prevQueryParams = { ...queryParamsObj };

      const haveQueryParamsChanged =
        newQueryParams.length !== prevQueryParams.length ||
        Object.keys(newQueryParams).some((key) => newQueryParams[key] !== prevQueryParams[key]);

      if (haveQueryParamsChanged) updateQueryParams(newQueryParams);
    },
    100,
    [JSON.stringify(defaultQueryParams)],
  );

  return { queryParams: queryParamsObj, updateQueryParams };
}

export function usePrevious(currentValue) {
  const prevValueRef = useRef(currentValue);

  if (currentValue !== prevValueRef.current && currentValue !== undefined) {
    prevValueRef.current = currentValue;
  }

  return prevValueRef.current;
}

export function useDelayedState(value, delay = 500) {
  const [delayedState, setDelayedState] = useState();

  useEffect(() => {
    setTimeout(() => {
      setDelayedState(value);
    }, delay);
  }, [value]);

  return delayedState;
}

export function useClientUsers({ clientId, search, page, roles, registrationStatus, length, orderDir, disabled }) {
  const { data, isLoading, isError } = _useClientUsers({
    clientId: clientId,
    search,
    page,
    roles: roles.join(","),
    registrationStatus: registrationStatus.join(","),
    length,
    orderDir,
    disabled,
  });
  const prevData = usePrevious(data);
  const { users = [], /* from, to,*/ total, availableRoles } = (isLoading ? prevData : data) ?? {};

  return { users, total, availableRoles, isLoading, isError };
}

export function useParentClientUsers({ clientId, search, page, roles = [], registrationStatus = [], length, orderDir, disabled }) {
  const { data, isLoading, isError } = _useParentClientUsers({
    clientId,
    search,
    page,
    roles: roles.join(","),
    registrationStatus: registrationStatus.join(","),
    length,
    orderDir,
    disabled,
  });
  const prevData = usePrevious(data);
  const { users = [], /* from, to,*/ total, availableRoles } = (isLoading ? prevData : data) ?? {};

  return { users, total, availableRoles, isLoading, isError };
}

export function useIsInView({ element, scrollContainer, boundaryElement }) {
  const [isInView, setIsInView] = useState();

  const checkIsInView = () => {
    const boundaryRect = boundaryElement?.getBoundingClientRect();
    const elementRect = element?.getBoundingClientRect();
    const isElementInView = boundaryRect
      ? elementRect?.top > boundaryRect?.top && elementRect?.top <= boundaryRect?.bottom
      : elementRect?.top < window.innerHeight && elementRect?.bottom >= 0;

    setIsInView(isElementInView);
  };

  useEffect(() => {
    checkIsInView();

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", checkIsInView);
      return () => scrollContainer.removeEventListener("scroll", checkIsInView);
    }
  }, [element, boundaryElement, scrollContainer]);

  return isInView;
}

export function useMutliClientUserAccessFlags() {
  const clients = useSelector((state) => state.app.clients);

  return useQueries({
    queries: clients.map((client) => ({
      queryKey: ["user-access-flags", { clientId: client.id }],
      queryFn: async () => fetchUserAccessFlags({ clientId: client.id }),
    })),
    combine: (results) => {
      return {
        data: results.map((result, index) => ({ clientId: clients[index]?.id, flags: result.data ?? [] })),
        isLoading: clients.length === 0 || results.some((result) => result.isLoading),
      };
    },
  });
}
