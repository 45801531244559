import { Pill, Tooltip } from "@darktrace/ui-components";
import React, { useState } from "react";
import styled from "styled-components";
import { TenantAccessModal } from "./TenantAccessModal.jsx";
import { useActiveClientId } from "../../logic/hooks.js";

const StyledTenants = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;

  > .bold {
    white-space: nowrap;
    font-weight: 700;
  }

  .dt-ui-tag__button-text {
    padding: 0.1rem 0.7rem;
  }
`;

export function Tenants({ userId, clientId, username, email, isYou, userClients = [] }) {
  const [showTenantAccessModal, setShowTenantAccessModal] = useState(false);
  const modalRoot = document.querySelector("#modal-root");

  const activeClientId = useActiveClientId();

  const clientName = userClients.find((client) => client.id === clientId).name;

  return (
    <StyledTenants className="tenants">
      <Tooltip title={t(`Edit Tenant Access`)}>
        <Pill size="small" colorName="basic" onClick={() => setShowTenantAccessModal((prev) => !prev)}>
          {userClients.length}
        </Pill>
      </Tooltip>

      {activeClientId !== clientId && <span className="bold">{clientName}</span>}

      <TenantAccessModal
        userId={userId}
        clientId={clientId}
        username={username}
        userClients={userClients}
        email={email}
        isYou={isYou}
        modalRoot={modalRoot}
        open={showTenantAccessModal}
        onClose={() => setShowTenantAccessModal(false)}
        keepMoounted={false}
      />
    </StyledTenants>
  );
}
