// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/@darktrace/ui-components/dist/css/dt-ui-font-awesome.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/@darktrace/ui-components/dist/css/dt-ui-fonts.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/css-loader/dist/cjs.js!./variables.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  /* fonts */
  --font-family: "Proxima Nova", Arial, Helvetica, sans-serif;
  font-family: var(--font-family);
  line-height: 1.5;
  font-weight: 400;
  font-size: 10px;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  color-scheme: light dark;
}

body {
  font-size: 12px;
}

#app-root {
  width: 100vw;
  height: 100vh;

  color: var(--dt-ui-text-color);
  background-color: var(--dt-ui-page-bg);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

img,
svg,
video {
  max-width: 100%;
  max-height: 100%;
}

body,
h1,
h2,
h3,
h4,
p {
  color: inherit;
  padding: 0;
  margin: 0;
}

a:not(.dt-ui-link) {
  text-decoration: none;
  color: inherit;
}

/* app specific */

#user-settings .dt-ui-dropdown__contents .dt-ui-dropdown__option:last-child {
  color: var(--dt-ui-critical-400);
}
`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAIA;EACE,UAAU;EACV,2DAA2D;EAC3D,+BAA+B;EAC/B,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,oBAAoB;EACpB,kCAAkC;EAClC,mCAAmC;EACnC,kCAAkC;EAClC,8BAA8B;EAC9B,wBAAwB;AAC1B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,aAAa;;EAEb,8BAA8B;EAC9B,sCAAsC;AACxC;;AAEA;;;EAGE,sBAAsB;AACxB;;AAEA;;;EAGE,eAAe;EACf,gBAAgB;AAClB;;AAEA;;;;;;EAME,cAAc;EACd,UAAU;EACV,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA,iBAAiB;;AAEjB;EACE,gCAAgC;AAClC","sourcesContent":["@import \"@darktrace/ui-components/dist/css/dt-ui-font-awesome.css\";\n@import \"@darktrace/ui-components/dist/css/dt-ui-fonts.css\";\n@import \"./variables.css\";\n\n:root {\n  /* fonts */\n  --font-family: \"Proxima Nova\", Arial, Helvetica, sans-serif;\n  font-family: var(--font-family);\n  line-height: 1.5;\n  font-weight: 400;\n  font-size: 10px;\n  font-synthesis: none;\n  text-rendering: optimizeLegibility;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  -webkit-text-size-adjust: 100%;\n  color-scheme: light dark;\n}\n\nbody {\n  font-size: 12px;\n}\n\n#app-root {\n  width: 100vw;\n  height: 100vh;\n\n  color: var(--dt-ui-text-color);\n  background-color: var(--dt-ui-page-bg);\n}\n\n*,\n*::before,\n*::after {\n  box-sizing: border-box;\n}\n\nimg,\nsvg,\nvideo {\n  max-width: 100%;\n  max-height: 100%;\n}\n\nbody,\nh1,\nh2,\nh3,\nh4,\np {\n  color: inherit;\n  padding: 0;\n  margin: 0;\n}\n\na:not(.dt-ui-link) {\n  text-decoration: none;\n  color: inherit;\n}\n\n/* app specific */\n\n#user-settings .dt-ui-dropdown__contents .dt-ui-dropdown__option:last-child {\n  color: var(--dt-ui-critical-400);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
