import React, { useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Button, Dropdown, GhostElement, SearchField, TileContainer, Tooltip, useDebouncedEffect } from "@darktrace/ui-components";
import { Alert } from "./AlertTile.jsx";
import { usePTNAlerts } from "../../../logic/api.js";
import { defaultSOCFilters, isValidHostname } from "../utils.js";
import { useQueryParams } from "../../../logic/hooks.js";
import { useActiveClientId } from "../../../logic/hooks.js";

const StyledPTNAlerts = styled.div`
  overflow: hidden;
  height: 100%;
  .dt-ui-tile-container {
    padding: 1.6rem;
    height: calc(100% - 8.6rem);
    overflow: scroll;
    .alerts-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
    }
    .dt-ui-ghost-element {
      height: 100%;
    }
    .infinite-scroll-text {
      text-align: center;
    }
  }
`;

const StyledFilters = styled.div`
  height: fit-content;
  border-bottom: 1px solid var(--dt-ui-card-border);
  display: grid;
  align-items: end;
  padding: 1.6rem;
  padding-bottom: 2.2rem;
  gap: 0.8rem;
  grid-template-columns: repeat(4, 1fr) auto;
  p {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.2rem;
    letter-spacing: 0.15rem;
    margin-bottom: 0.8rem;
  }
  .dt-ui-input-search {
    width: 100%;
  }
`;

export function PTNAlerts() {
  const activeClientId = useActiveClientId();
  const [searchValue, setSearchValue] = useState("");
  const [searchTooltip, setSearchTooltip] = useState("");
  const { queryParams, updateQueryParams } = useQueryParams({
    defaultQueryParams: { clientId: activeClientId, ...defaultSOCFilters },
  });

  const statuses = queryParams.statuses?.split(",") || null;
  const types = queryParams.types?.split(",") || null;
  const regions = queryParams.regions?.split(",") || null;
  const hostname = queryParams.hostname || null;
  const assignee_id = queryParams.assignee_id || null;

  const {
    data: alerts,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = usePTNAlerts({ clientId: activeClientId, filters: { statuses, hostname, types, regions, assignee_id } });

  const dropdowns = useMemo(() => {
    return [
      {
        id: "types",
        title: t(`Type`),
        disabled: true,
        items: [
          { label: t("SOC"), id: "soc" },
          { label: t("Trial"), id: "trial" },
          { label: t("POV"), id: "pov" },
        ].map(({ id, label }) => ({
          id,
          label,
          selected: types?.includes(id),
          // disabled: types?.length === 1 && types[0] === id,
        })),
        onSelect: (value) => handleSelect(types, "types", value),
        onSelectAll: () => handleSelectAll("types", []),
        // disableSelectAll: types.length === 4,
      },
      {
        id: "statuses",
        title: t(`Status`),
        items: [
          { label: t(`Not Assigned`), id: "new" },
          { label: t(`In Progress`), id: "inprogress" },
          { label: t(`Escalate Alert`), id: "escalated" },
          { label: t(`Resolved`), id: "resolved" },
        ].map(({ id, label }) => ({
          id,
          label,
          selected: statuses?.includes(id),
          disabled: statuses?.length === 1 && statuses[0] === id,
        })),
        onSelect: (value) => handleSelect(statuses, "statuses", value),
        onSelectAll: () => handleSelectAll("statuses", ["new", "inprogress", "escalated", "resolved"]),
        disableSelectAll: statuses.length === 4,
      },
      {
        id: "regions",
        title: t(`Region`),
        disabled: true,
        items: [],
        onSelect: (value) => handleSelect(regions, "regions", value),
        onSelectAll: () => handleSelectAll("regions", []),
        //disableSelectAll: regions.length === 4,
      },
    ];
  }, [queryParams]);

  function handleSelect(queryParamVal, dropdownId, selectedValue) {
    const newFilters =
      !queryParamVal || !queryParamVal?.length
        ? // Only filter applied
          selectedValue
        : queryParamVal.find((x) => x === selectedValue)
          ? // Remove filter
            queryParamVal.filter((x) => x !== selectedValue)
          : // Add filter
            [queryParamVal, selectedValue].flat();
    updateQueryParams({ [dropdownId]: newFilters });
  }

  function handleSelectAll(type, allItems) {
    return updateQueryParams({ [type]: allItems });
  }

  function resetFilters() {
    setSearchValue("");
    setSearchTooltip("");
    updateQueryParams(defaultSOCFilters);
  }

  function handleSearchChange(newValue) {
    setSearchValue(newValue);
  }

  function updateHomenameQuery(searchTerm) {
    if (isValidHostname(searchTerm) || !searchTerm) {
      setSearchTooltip("");
    } else {
      setSearchTooltip(t(`Invalid hostname`));
      return;
    }
    const newValue = !searchTerm && queryParams.hostname ? null : searchTerm;

    updateQueryParams({ hostname: newValue });
  }

  useDebouncedEffect(() => updateHomenameQuery(searchValue), 2000, [searchValue]);

  // Infinite scrolling logic
  const observerRef = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading || isFetchingNextPage) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage],
  );

  return (
    <StyledPTNAlerts id="ptn-alerts">
      <StyledFilters>
        {dropdowns.map(({ id, title, ...options }) => (
          <span key={id}>
            <p>{title}</p>
            <Dropdown checkbox {...options} />
          </span>
        ))}
        <Tooltip text={searchTooltip}>
          <span>
            <SearchField placeholder={t(`Hostname`)} value={searchValue} onChange={handleSearchChange} />
          </span>
        </Tooltip>
        <Button variant="secondary" icon="sync" onClick={resetFilters}>
          {t(`Reset`)}
        </Button>
      </StyledFilters>
      <TileContainer>
        {isLoading ? (
          <GhostElement />
        ) : isError || !alerts?.pages[0] ? (
          <div>{t(`Failed to load PTN alerts.`)}</div>
        ) : (
          <>
            {alerts.pages.map((page, pageIndex) => (
              <div className="alerts-wrapper" key={pageIndex}>
                {page.data.map((alert) => (
                  <Alert key={alert.id} {...{ alert }} />
                ))}
              </div>
            ))}
            <div className="infinite-scroll-text" ref={lastElementRef}>
              {hasNextPage || alerts.pages[0].last_page === 1 ? "" : t(`No more results.`)}
            </div>
          </>
        )}
      </TileContainer>
    </StyledPTNAlerts>
  );
}
