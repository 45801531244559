import { Button, ModalCard } from "@darktrace/ui-components";
import React from "react";
import styled from "styled-components";

const StyledModalCard = styled(ModalCard)`
  .dt-ui-modal-card {
    display: flex;
    flex-direction: column;

    .dt-ui-card__contents {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      height: 100%;
      gap: 1.2rem;

      .action-buttons {
        align-self: flex-end;
        display: flex;
        gap: 0.8rem;
      }
    }
  }
`;

export function Confirmation({ title, message, confirmButtonText = t(`Confirm`), open, onConfirm = () => {}, onClose }) {
  const modalRoot = document.querySelector("#modal-root");

  return (
    <StyledModalCard open={open} title={title} onClose={onClose} modalRoot={modalRoot}>
      {message && <div>{message}</div>}
      <div className="action-buttons">
        <Button variant="secondary" onClick={onClose}>
          {t(`Cancel`)}
        </Button>
        <Button onClick={onConfirm}>{confirmButtonText}</Button>
      </div>
    </StyledModalCard>
  );
}
