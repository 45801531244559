import React from "react";
import styled from "styled-components";

const StyledPaginationStats = styled.span`
  .bold {
    font-weight: 700;
  }
`;

export function PaginationStats({ showingEntriesFrom, showingEntriesTo, numEntries }) {
  return (
    <StyledPaginationStats className="pagination-stats">
      <span className="bold">{showingEntriesFrom}</span>-<span className="bold">{showingEntriesTo}</span>
      <span> {pt(`Count, e.g. 4 out of 10`, `of`)} </span>
      <span className="bold">{numEntries}</span>
    </StyledPaginationStats>
  );
}
