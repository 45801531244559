import {
  Button,
  Card,
  CollapsableCard,
  Dropdown,
  IconButton,
  Input,
  ModalCard,
  Pill,
  Section,
  Tile,
  TileContainer,
} from "@darktrace/ui-components";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useClientApplications,
  useCreateClientApplicationMutation,
  useDeleteClientApplicationMutation,
  useEditClientApplicationMutation,
  useMultiInstanceProductGroups,
} from "../../logic/api.js";
import { useActiveClientId, useQueryParams } from "../../logic/hooks.js";
import { useSelector } from "react-redux";

const StyledIntegrations = styled.div`
  height: 100%;

  display: flex;
  gap: 2rem;

  .integrations-sidebar {
    flex-shrink: 0;

    .content {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      height: 100%;

      .create-application-btn {
        width: 100%;
      }

      .applications-section {
        flex-basis: 0;
        flex-grow: 1;
        overflow-y: auto;

        .applications {
          display: flex;
          flex-direction: column;
          gap: 0.8rem;
          padding-right: 0.6rem;
        }
      }
    }
  }

  .integrations-panel {
    flex-grow: 1;

    .integrations-panel-content {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;

      .application-title {
        font-weight: 700;
        font-size: 2.4rem;
      }

      .application-scopes {
        display: flex;
        gap: 0.8rem;
        flex-wrap: wrap;
      }
    }
  }
`;

export function Integrations() {
  const [isCreatingNewApplication, setIsCreatingNewApplication] = useState(false);
  const [selectedApplicationId, setSelectedApplicationId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [collapsed, setCollapsed] = useState(false);
  const activeClientId = useActiveClientId();

  const { data: clientApplications = [] } = useClientApplications({ clientId: activeClientId });
  const { data: productGroups } = useMultiInstanceProductGroups({ clientId: activeClientId });

  const selectedApplication = clientApplications.find((application) => application.id === selectedApplicationId);
  const selectedApplicationGroups =
    selectedApplication?.groups?.map((group) => {
      const productGroup =
        productGroups.find((_productGroup) => _productGroup.id === group.id && _productGroup.instanceId === group.instanceId) ?? {};

      return { ...group, ...productGroup };
    }) ?? [];

  // TODO: remove this, just to ensure test data is works
  const defaultClientId = useSelector((state) => state.app.defaultClientId);
  const { updateQueryParams } = useQueryParams();
  useEffect(() => {
    updateQueryParams({ clientId: defaultClientId });
  }, []);

  return (
    <StyledIntegrations>
      <CollapsableCard
        className="integrations-sidebar"
        title={t(`Darktrace ActiveAI Security Portal`)}
        width={400}
        collapsed={collapsed}
        onClickCollapsedToggle={() => setCollapsed((prev) => !prev)}
      >
        {!collapsed && (
          <div className="content">
            <Button
              className="create-application-btn"
              icon="plus"
              size="small"
              onClick={() => {
                setShowModal((prev) => !prev);
                setIsCreatingNewApplication(true);
              }}
            >
              Create New Application
            </Button>

            <Section title={t(`Applications`)} className="applications-section">
              <TileContainer className="applications">
                {clientApplications.map((application) => (
                  <Tile
                    key={application.id}
                    active={selectedApplicationId === application.id}
                    noColorBar
                    header={`${application.name} - ${application.groups.length} ${application.groups.length === 1 ? t(`scope`) : t(`scopes`)}`}
                    onClick={() => setSelectedApplicationId(application.id)}
                  />
                ))}
              </TileContainer>
            </Section>
          </div>
        )}
      </CollapsableCard>

      {selectedApplicationId && (
        <Card
          className="integrations-panel"
          title={t(`Application Details`)}
          titleRight={
            <>
              <IconButton
                icon="pen"
                variant="tertiary"
                onClick={() => {
                  setShowModal(true);
                  setIsCreatingNewApplication(false);
                }}
              />

              <IconButton
                variant="tertiary"
                icon="trash"
                // onClick={() => deleteClientApplication().then(() => setSelectedApplicationId(null))}
                onClick={() => setShowConfirmDeleteModal(true)}
              />
            </>
          }
        >
          <div className="integrations-panel-content">
            <span className="application-title">{selectedApplication.name}</span>

            <Section title={t(`Scopes`)}>
              <div className="application-scopes">
                {selectedApplicationGroups.map(({ id, instanceName, displayName }) => (
                  <Pill key={id} text={instanceName} scopedText={displayName} colorName="glacier" />
                ))}
              </div>
            </Section>
          </div>
        </Card>
      )}

      <ApplicationDetailsModal
        open={showModal}
        onClose={() => setShowModal(false)}
        selectedApplicationId={isCreatingNewApplication ? null : selectedApplicationId}
        setSelectedApplicationId={setSelectedApplicationId}
      />

      <ConfirmDeleteApplicationModal
        open={showConfirmDeleteModal}
        onClose={() => setShowConfirmDeleteModal(false)}
        selectedApplication={selectedApplication}
        setSelectedApplicationId={setSelectedApplicationId}
      />
    </StyledIntegrations>
  );
}

const StyledModalCard = styled(ModalCard)`
  .content {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .top {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      .grid {
        display: grid;
        gap: 0.8rem 0.4rem;
        grid-template-columns: auto 1fr;

        .field-name {
          position: relative;
          top: 0.7rem;
          font-weight: 700;
        }

        .field-value {
          width: 100%;
        }
      }
    }

    .action-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 0.8rem;
    }
  }
`;

function ApplicationDetailsModal({ open, onClose, selectedApplicationId, setSelectedApplicationId }) {
  const modalRoot = document.querySelector("#modal-root");
  const [name, setName] = useState("");
  const [selectedProductGroupIds, setSelectedProductGroupIds] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const activeClientId = useActiveClientId();

  const { data: clientApplications = [] } = useClientApplications({ clientId: activeClientId });

  const clientApplicationIdsHash = clientApplications.map(({ id }) => id).join("-");
  const isNameValid =
    name.length > 0 && !clientApplications.some((application) => application.name === name && application.id !== selectedApplicationId);

  const { data: productGroups, isLoading: isLoadingProductGroups } = useMultiInstanceProductGroups({
    clientId: activeClientId,
    state: "UP",
  });

  const formattedProductGroups = productGroups.map(({ id, instanceId, scope, instanceName, displayName }) => ({
    id: `${instanceId}::::${id}`,
    groupId: id,
    instanceId,
    scope,
    label: `${instanceName}::${displayName}`,
    selected: selectedProductGroupIds.includes(`${instanceId}::::${id}`),
  }));
  const selectedProductGroups = selectedProductGroupIds.map((id) => {
    const productGroup = formattedProductGroups.find((group) => group.id === id);
    return { id: productGroup.groupId, instanceId: productGroup.instanceId, scope: productGroup.scope };
  });

  const productGroupDropdownItems = formattedProductGroups.map(({ id, label, selected }) => ({ id, label, selected }));

  const { mutateAsync: createClientApplication } = useCreateClientApplicationMutation({
    name,
    groups: selectedProductGroups,
  });

  const { mutateAsync: editClientApplication } = useEditClientApplicationMutation({
    applicationId: selectedApplicationId,
    clientId: activeClientId,
    name,
    groups: selectedProductGroups,
  });

  useEffect(() => {
    if (selectedApplicationId) {
      const application = clientApplications.find((clientApplication) => clientApplication.id === selectedApplicationId);
      if (application) {
        setName(application.name);
        setSelectedProductGroupIds(application.groups.map(({ instanceId, id }) => `${instanceId}::::${id}`));
      }
    } else {
      setName("");
      setSelectedProductGroupIds([]);
    }
  }, [selectedApplicationId, clientApplicationIdsHash]);

  function handleSelectProductGroup(id) {
    setSelectedProductGroupIds((prevSelectedProductGroupIds) => {
      if (prevSelectedProductGroupIds.includes(id)) {
        return prevSelectedProductGroupIds.filter((prevSelectedGroupId) => prevSelectedGroupId !== id);
      } else return [...prevSelectedProductGroupIds, id];
    });
  }

  function handleSelectAllProductGroups() {
    setSelectedProductGroupIds((prevSelectedProductGroups) => {
      if (prevSelectedProductGroups.length === productGroups.length) return [];
      else return productGroups.map(({ id, instanceId }) => `${instanceId}::::${id}`);
    });
  }

  function handleChangeName(value) {
    setName(value);
    setShowErrorMessage(false);
  }

  function handleSave() {
    if (!isNameValid) return setShowErrorMessage(true);
    if (selectedApplicationId) {
      editClientApplication().then(onClose);
    } else {
      createClientApplication().then((application) => {
        onClose();
        setSelectedApplicationId(application.id);
      });
    }
  }

  return (
    <StyledModalCard
      open={open}
      modalRoot={modalRoot}
      onClose={onClose}
      title={selectedApplicationId ? t(`Edit Application`) : t(`Create New Application`)}
    >
      <div className="content">
        <div className="top">
          <div className="grid">
            <span className="field-name">{t(`Name`)}:</span>
            <Input
              className="field-value"
              value={name}
              onChange={handleChangeName}
              valid={showErrorMessage ? (isNameValid ? undefined : false) : undefined}
              invalidMsg={name.length === 0 ? t(`Name field must not be empty`) : t(`Name is already taken`)}
              placeholder={t(`Application Name`)}
            />

            <span className="field-name">{t(`Scopes`)}:</span>

            <Dropdown
              checkbox
              displayValue={isLoadingProductGroups ? t(`Loading...`) : undefined}
              disabled={isLoadingProductGroups}
              items={productGroupDropdownItems}
              onSelect={handleSelectProductGroup}
              onSelectAll={handleSelectAllProductGroups}
            />
          </div>
        </div>

        <div className="action-buttons">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>

          <Button disabled={showErrorMessage && !isNameValid} onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </StyledModalCard>
  );
}

function ConfirmDeleteApplicationModal({ open, onClose, selectedApplication, setSelectedApplicationId }) {
  const modalRoot = document.querySelector("#modal-root");
  const activeClientId = useActiveClientId();

  const { mutateAsync: deleteClientApplication } = useDeleteClientApplicationMutation({
    clientId: activeClientId,
    applicationId: selectedApplication?.id,
  });

  return (
    <StyledModalCard open={open} modalRoot={modalRoot} onClose={onClose} title={t(`Delete Application`)}>
      <div className="content">
        <div className="top">
          <span>{t(`Are you sure you want to delete this application: ${selectedApplication?.name}`)}</span>
        </div>

        <div className="action-buttons">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>

          <Button
            onClick={() =>
              deleteClientApplication().then(() => {
                setSelectedApplicationId(null);
                onClose();
              })
            }
          >
            Delete
          </Button>
        </div>
      </div>
    </StyledModalCard>
  );
}
