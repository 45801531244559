import React, { useState } from "react";
import styled from "styled-components";
import { ChatMessages, Dropdown, GhostElement, ModalCard } from "@darktrace/ui-components";
import { usePTNAlertsIntel } from "../../../../logic/api";
import dayjs from "dayjs";
import { useActiveClientId } from "../../../../logic/hooks";

const StyledModalCard = styled(ModalCard)`
  .dt-ui-modal-card {
    width: 50vw;
    min-width: 45rem;
    display: flex;
    flex-direction: column;
    height: 60vh;
    .dt-ui-card__contents {
      padding: 0;
      overflow: hidden;
      .filters {
        gap: 1.2rem;
        padding: 1.6rem;
      }
      .dt-ui-chat__messages {
        padding: 1.6rem;
        padding-top: 0;
        overflow: auto;
        height: calc(100% - 6rem);
        .dt-ui-chat__bubble {
          background-color: var(--dt-ui-button-secondary-active-bg);
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
`;

export function IntelModal({ onClose = () => {}, open, alertId, ...attrs }) {
  const modalRoot = document.querySelector("#modal-root");
  const activeClientId = useActiveClientId();
  const { data: intel, isLoading, isError, isSuccess } = usePTNAlertsIntel({ alertId, clientId: activeClientId });
  const defaultFilters = { type: null, feedback_source: null, tir_number: null };
  const [filters, setFilters] = useState(defaultFilters);

  function handleClose() {
    onClose();
  }

  function handleSelect(id, type) {
    setFilters((prev) => ({ ...prev, [type]: prev[type] === id ? null : id }));
  }

  function formatIntel(intel) {
    const formattedIntel = intel.map(({ id, content, created_at }) => ({
      id,
      text: content,
      start: dayjs(created_at).valueOf(),
    }));
    return formattedIntel;
  }

  function filterIntel(intel) {
    return intel.filter((x) => Object.keys(filters).every((filter) => !filters[filter] || x.type === filters.type));
  }

  const typeItems = [
    { id: "General", label: t(`General`) },
    { id: "SOC", label: t(`SOC`) },
    { id: "Technical", label: t(`Technical`) },
    { id: "TIR", label: t(`TIR`) },
  ];

  return (
    <StyledModalCard modalRoot={modalRoot} onClose={handleClose} open={open} title={t("Account Intel")} {...attrs}>
      {(() => {
        if (isLoading) {
          return <GhostElement />;
        }

        if (isError) {
          return <div>{t(`Failed to load intel.`)}</div>;
        }
        if (isSuccess) {
          if (!intel?.length) {
            return <div>{t(`No intel to show.`)}</div>;
          }
          return (
            <>
              <div className="filters">
                {[
                  { id: "type", placeholder: t(`Feedback Type`), items: typeItems },
                  // { id: "feedback_source", placeholder: t(`Feedback Source`), items: [] },
                  // { id: "tir_number", placeholder: t(`TIR Number`), items: [] },
                ].map(({ id, placeholder, items }) => {
                  return (
                    <Dropdown
                      key={placeholder}
                      icon="filter far"
                      placeholder={placeholder}
                      items={items.map((x) => ({ ...x, selected: x.id === filters.type }))}
                      onSelect={(itemId) => handleSelect(itemId, id)}
                      displayValue={filters[id] ? `${t(`Feedback Type:`)} ${filters[id]}` : placeholder}
                    />
                  );
                })}
              </div>
              <ChatMessages messages={formatIntel(filterIntel(intel))} />
            </>
          );
        }

        return null;
      })()}
    </StyledModalCard>
  );
}
