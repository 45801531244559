import React from "react";
import styled from "styled-components";
import { Modal } from "@darktrace/ui-components";

const StyledModalCard = styled(Modal)`
  .dt-ui-modal {
    width: 50vw;
    display: flex;
    flex-direction: column;
    .dt-ui-modal__contents {
    }
  }
`;

export function NotifiedModal({ onClose = () => {}, open, ...attrs }) {
  const modalRoot = document.querySelector("#modal-root");

  function handleClose() {
    onClose();
  }

  return (
    <StyledModalCard
      modalRoot={modalRoot}
      onClose={handleClose}
      open={open}
      title={t(`Notifications about this alert have been sent to:`)}
      {...attrs}
    >
      {t(`Nothing to show.`)}
      {/* {isError ? <div>Failed to load data.</div> : null}
    {!comments?.length ? <div>No comments to show.</div> : null}
    {isLoading ? <GhostElement /> : <Chat onSendMessage={handleSendMessage} messages={formatComments(comments)} />} */}
    </StyledModalCard>
  );
}
