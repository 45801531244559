import { Button } from "@darktrace/ui-components";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useClientSAMLProviderAccessMethods,
  useClientSAMLProviderInfo,
  useCreateSAMLProviderMutation,
  useEditSAMLProviderMutation,
  useUpdateClientSAMLProviderAccessMethodMutation,
} from "../../logic/api.js";
import { useActiveClientId } from "../../logic/hooks.js";
import { SAMLProviderConfiguration } from "./SAMLProviderConfiguration.jsx";

const StyledSAMLProvider = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  overflow-y: auto;

  .saml-provider-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex-shrink: 1;
    overflow-y: auto;

    .saml-provider-toggles {
      display: flex;
      flex-direction: column;
      gap: 1.2rem;

      .custom-flow-toggle {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .custom-flow-toggle__switch {
          padding-inline: 0;

          .dt-ui-switch__label {
            margin-left: 0;
            padding-left: 0;
          }
        }
      }
    }
  }

  .name {
    font-size: 2.4rem;
    font-weight: 700;
  }

  .action-buttons {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1.2rem;
  }
`;

export function SAMLProvider({
  id,
  isNew = false,
  isEditing = false,
  isDeletingSAMLProvider,
  onClose = () => {},
  setSelectedSamlProviderId,
}) {
  const activeClientId = useActiveClientId();

  // queries
  const { data: samlProviderConfig = {} } = useClientSAMLProviderInfo({ clientId: activeClientId, clientSamlProviderId: id });
  const { data: samlProviderAccessMethods = [] } = useClientSAMLProviderAccessMethods({ clientSamlProviderId: id });

  // saved data
  const usernameAttributeName = samlProviderConfig.usernameAttributeName;
  const emailAttributeName = samlProviderConfig.emailAttributeName;
  const groupAttributeName = samlProviderConfig.groupAttributeName;
  const authenticationGroups = samlProviderConfig.authenticationGroups;
  const samlXmlMetadata = samlProviderConfig.samlXmlMetadata;
  const alias = samlProviderConfig.alias;

  // local data
  const [localUsernameAttributeName, setLocalUsernameAttributeName] = useState();
  const [localEmailAttributeName, setLocalEmailAttributeName] = useState();
  const [localGroupAttributeName, setLocalGroupAttributeName] = useState();
  const [localAuthenticationGroups, setLocalAuthenticationGroups] = useState();
  const [localSamlXmlMetadata, setLocalSamlXmlMetadata] = useState();
  const [localAlias, setLocalAlias] = useState();
  const [localAccessTypes, setLocalAccessTypes] = useState([]);

  function resetFields() {
    setLocalUsernameAttributeName(usernameAttributeName);
    setLocalEmailAttributeName(emailAttributeName);
    setLocalGroupAttributeName(groupAttributeName);
    setLocalAuthenticationGroups(authenticationGroups);
    setLocalSamlXmlMetadata(samlXmlMetadata);
    setLocalAlias(alias);
    setLocalAccessTypes(samlProviderAccessMethods);
  }

  function handleClose() {
    onClose();
    resetFields();
  }

  useEffect(() => {
    resetFields();
  }, [JSON.stringify(samlProviderConfig)]);

  const localSamlProviderConfig = {
    usernameAttributeName: localUsernameAttributeName,
    emailAttributeName: localEmailAttributeName,
    groupAttributeName: localGroupAttributeName,
    authenticationGroups: localAuthenticationGroups,
    samlXmlMetadata: localSamlXmlMetadata,
    alias: localAlias,
  };

  const accessTypesWithChanges = localAccessTypes.filter((localAccessType) =>
    samlProviderAccessMethods.some(
      (accessMethod) => accessMethod.accessType === localAccessType.accessType && accessMethod.method !== localAccessType.method,
    ),
  );

  const hasChanges =
    usernameAttributeName !== localUsernameAttributeName ||
    emailAttributeName !== localEmailAttributeName ||
    groupAttributeName !== localGroupAttributeName ||
    authenticationGroups !== localAuthenticationGroups ||
    samlXmlMetadata !== localSamlXmlMetadata ||
    alias !== localAlias ||
    accessTypesWithChanges.length > 0;

  function onChangeField(fieldName, value) {
    if (fieldName === "usernameAttributeName") setLocalUsernameAttributeName(value);
    if (fieldName === "emailAttributeName") setLocalEmailAttributeName(value);
    if (fieldName === "groupAttributeName") setLocalGroupAttributeName(value);
    if (fieldName === "authenticationGroups") setLocalAuthenticationGroups(value);
    if (fieldName === "samlXmlMetadata") setLocalSamlXmlMetadata(value);
    if (fieldName === "alias") setLocalAlias(value);

    // special handling required for samlGroupTransparentMode
    if (fieldName.startsWith("samlGroupTransparentMode")) {
      const accessType = fieldName.split("::")[1];
      setLocalAccessTypes((prevLocalAccessTypes) =>
        prevLocalAccessTypes.map((prevLocalAccessType) =>
          prevLocalAccessType.accessType === accessType ? { ...prevLocalAccessType, method: value } : prevLocalAccessType,
        ),
      );
    }
  }

  // mutations
  const { mutate: createSAMLProviderMutation = [], isPending: isCreatingSAMLProvider } = useCreateSAMLProviderMutation({
    clientId: activeClientId,
    samlProviderConfig: localSamlProviderConfig,
    onSuccess: (res) => {
      onClose();
      setSelectedSamlProviderId(res.id);
    },
  });

  const { mutate: editSAMLProviderMutation = [], isPending: isEditingSAMLProvider } = useEditSAMLProviderMutation({
    id,
    samlProviderConfig: localSamlProviderConfig,
    onSuccess: onClose,
  });

  const { mutate: updateClientSAMLProviderAccessMethodMutation, isPending: isUpdatingAccessMethod } =
    useUpdateClientSAMLProviderAccessMethodMutation({ clientSamlProviderId: id });

  function handleEditSAMLProvider() {
    editSAMLProviderMutation();
    accessTypesWithChanges.forEach(({ accessType, method }) => updateClientSAMLProviderAccessMethodMutation({ accessType, method }));
  }

  const disabled = isCreatingSAMLProvider || isEditingSAMLProvider || isUpdatingAccessMethod || isDeletingSAMLProvider || !hasChanges;

  return (
    <StyledSAMLProvider>
      <div className="saml-provider-content">
        <SAMLProviderConfiguration
          clientSamlProviderId={id}
          isNew={isNew}
          isEditing={isEditing}
          samlProviderConfig={localSamlProviderConfig}
          accessTypes={localAccessTypes}
          onChangeField={onChangeField}
        />
      </div>

      {isNew ? (
        <div className="action-buttons">
          <Button icon="times" variant="secondary" className="remove-btn" onClick={handleClose}>
            {t(`Cancel`)}
          </Button>
          <Button icon="plus" disabled={disabled} onClick={() => createSAMLProviderMutation()}>
            {t(`Add SAML Provider`)}
          </Button>
        </div>
      ) : isEditing ? (
        <div className="action-buttons">
          <Button icon="times" variant="secondary" className="remove-btn" onClick={handleClose}>
            {t(`Cancel`)}
          </Button>
          <Button icon="save" disabled={disabled} onClick={handleEditSAMLProvider}>
            {t(`Save`)}
          </Button>
        </div>
      ) : null}
    </StyledSAMLProvider>
  );
}
