import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Mappings } from "./Mappings.jsx";
import {
  fetchClientSAMLProviderRoleMappings,
  useClientAvailableRoles,
  useClientSAMLProviderProfiles,
  useClientSAMLProviderRoleMappings,
  useCreateClientSAMLProviderProfileMutation,
  useEditClientSAMLProviderRoleMappingsMutation,
} from "../../logic/api.js";
import { Button, Card, DropdownDropper, Input, ModalCard } from "@darktrace/ui-components";
import { useActiveClientId } from "../../logic/hooks.js";
import { useQueries } from "@tanstack/react-query";

const StyledSAMLRolesCard = styled(Card)`
  .dt-ui-card__contents {
    padding: 0;
  }
`;

export function SAMLRolesCard({ clientSamlProviderId, alias }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const activeClientId = useActiveClientId();
  const { data: availableRoles = [], isLoading: isLoadingAvailableRoles } = useClientAvailableRoles({ clientId: activeClientId });

  const { data: clientSAMLProviderProfiles = [], isLoading: isLoadingClientSAMLProviderProfiles } = useClientSAMLProviderProfiles({
    clientSamlProviderId,
  });

  const { data: roleMappings = [], isLoading: isLoadingRoleMappings } = useQueries({
    queries: clientSAMLProviderProfiles.map((profile) => ({
      queryKey: ["client-saml-provider-role-mappings", { profileId: profile.id }],
      queryFn: async () => fetchClientSAMLProviderRoleMappings({ profileId: profile.id }),
    })),
    combine: (results) => {
      return {
        data: results.map((result, index) => ({
          profileId: clientSAMLProviderProfiles[index].id,
          group: clientSAMLProviderProfiles[index].group,
          roleIds: result.data?.map((data) => data.roleId) ?? [],
        })),
        isLoading: results.some((result) => result.isLoading),
      };
    },
  });

  const formattedRoleMappings = roleMappings.map(({ profileId, group, roleIds }) => ({
    name: group,
    profileId: profileId,
    items: roleIds?.map((roleId) => ({
      id: roleId,
      text: availableRoles.find((role) => role.id === roleId)?.name ?? (isLoadingAvailableRoles ? "Loading" : "Unknown Role"),
    })),
  }));

  const isLoading = isLoadingClientSAMLProviderProfiles || isLoadingRoleMappings;

  return (
    <>
      <StyledSAMLRolesCard
        title={t(alias ? `ActiveAI Security Portal Roles (${alias})` : `ActiveAI Security Portal Roles`)} // TODO
        titleRight={
          <Button icon="plus" size="small" onClick={() => setIsModalOpen((prev) => !prev)}>
            Create SAML Role Mapping
          </Button>
        }
      >
        <Mappings mappings={formattedRoleMappings} type="role" isLoadingMappings={isLoading} clientSamlProviderId={clientSamlProviderId} />
      </StyledSAMLRolesCard>

      <SAMLRoleMappingsModal open={isModalOpen} onClose={() => setIsModalOpen(false)} clientSamlProviderId={clientSamlProviderId} />
    </>
  );
}

const StyledModalCard = styled(ModalCard)`
  .dt-ui-card__contents {
    padding: 0;
  }
`;

export function SAMLRoleMappingsModal({ open, onClose, samlGroup, profileId, clientSamlProviderId }) {
  return (
    <StyledModalCard
      open={open}
      title={samlGroup ? t(`Edit Role Mapping`) : t(`Create New Role Mapping`)}
      onClose={onClose}
      keepMounted={false}
    >
      <RoleMappings samlGroup={samlGroup} profileId={profileId} onClose={onClose} clientSamlProviderId={clientSamlProviderId} />
    </StyledModalCard>
  );
}

const StyledRoleMappings = styled.div`
  .saml-group-name {
    padding: 0.8rem 1.6rem;
    display: flex;
    gap: 2rem;
    align-items: flex-start;

    > span {
      position: relative;
      top: 0.6rem;
      white-space: nowrap;
    }

    .dt-ui-input__wrapper {
      width: 100%;
    }
  }

  .select-roles {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .dt-ui-dropdown {
      width: 100%;
      box-shadow: none;
      border-radius: 0;
      border-width: 1px 0 0 0;
    }
  }

  .action-buttons {
    padding: 0.8rem;
    display: flex;
    gap: 1.2rem;

    > * {
      flex: 1;
    }
  }
`;

function RoleMappings({ samlGroup, profileId, onClose, clientSamlProviderId }) {
  const isNew = !profileId;
  const { data: roleMappings = [] } = useClientSAMLProviderRoleMappings({ profileId });

  const initialSelectedRoleIds = roleMappings.map((roleMapping) => roleMapping.roleId);
  const initialSelectedRoleIdsHash = initialSelectedRoleIds.join("-");
  const [selectedRoleIds, setSelectedRoleIds] = useState([]);
  useEffect(() => {
    setSelectedRoleIds(initialSelectedRoleIds);
  }, [initialSelectedRoleIdsHash]);

  const activeClientId = useActiveClientId(Card);
  const { data: availableRoles = [] } = useClientAvailableRoles({ clientId: activeClientId });
  const dropdownItems = availableRoles.map((role) => ({ ...role, label: role.name, selected: selectedRoleIds.includes(role.id) }));

  const [samlGroupName, setSamlGroupName] = useState(samlGroup);
  const { data: clientSAMLProviderProfiles = [] } = useClientSAMLProviderProfiles({ clientSamlProviderId });
  const isExistingProfileWithSameGroupName = clientSAMLProviderProfiles.some((profile) => profile.group === samlGroupName);

  const { mutateAsync: createSAMLProviderProfile, isPending: isCreatingSAMLProviderProfile } = useCreateClientSAMLProviderProfileMutation({
    clientSamlProviderId,
  });
  const { mutateAsync: editProfileRoleMappings, isPending: isEditingMappings } = useEditClientSAMLProviderRoleMappingsMutation({
    profileId,
  });

  function handleSelectItem(id) {
    setSelectedRoleIds((prevSelectedRoleIds) => {
      if (prevSelectedRoleIds.includes(id)) return prevSelectedRoleIds.filter((roleId) => roleId !== id);
      else return [...prevSelectedRoleIds, id];
    });
  }

  function handleSelectAllItems() {
    setSelectedRoleIds((prevSelectedRoleIds) => {
      const areAllItemsAlreadySelected = prevSelectedRoleIds.length === availableRoles.length;
      if (areAllItemsAlreadySelected) return [];
      else return availableRoles.map((role) => role.id);
    });
  }

  const roleIdsToAdd = selectedRoleIds.filter((roleId) => !initialSelectedRoleIds.includes(roleId));
  const roleIdsToRemove = initialSelectedRoleIds.filter((roleId) => !selectedRoleIds.includes(roleId));

  function handleSaveMappings() {
    if (isNew) {
      createSAMLProviderProfile(samlGroupName).then((res) => {
        const responseData = res.data.profile;
        editProfileRoleMappings({ profileId: responseData.id, add: roleIdsToAdd }).then(onClose);
      });
    } else {
      editProfileRoleMappings({ add: roleIdsToAdd, remove: roleIdsToRemove }).then(onClose);
    }
  }

  const disabled =
    !samlGroupName ||
    (isNew && isExistingProfileWithSameGroupName) ||
    isCreatingSAMLProviderProfile ||
    (roleIdsToAdd.length === 0 && roleIdsToRemove.length === 0) ||
    isEditingMappings;

  return (
    <StyledRoleMappings>
      <div className="saml-group-name">
        <span>SAML Group Name:</span>
        <Input
          value={samlGroupName}
          onChange={isNew ? setSamlGroupName : undefined}
          disabled={!isNew}
          placeholder="Type here..."
          valid={isNew && isExistingProfileWithSameGroupName ? false : undefined}
          invalidMsg={t(`Group name already in use`)}
        />
      </div>

      <div className="select-roles">
        <DropdownDropper checkbox usePopper={false} items={dropdownItems} onSelect={handleSelectItem} onSelectAll={handleSelectAllItems} />
      </div>

      <div className="action-buttons">
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>

        <Button disabled={disabled} onClick={handleSaveMappings} loading={isEditingMappings}>
          Save
        </Button>
      </div>
    </StyledRoleMappings>
  );
}
