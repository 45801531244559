import "../styles/index.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { HashRouter as Router } from "react-router-dom";
import { UIComponentsProvider } from "@darktrace/ui-components";

import { Provider } from "react-redux";
import { store } from "./store.js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { t, nt, pt } from "./translator.js";

import { AuthenticatedRouter } from "./AuthenticatedRouter.jsx";

// Set global mock translator functions
globalThis.t = t;
globalThis.nt = nt;
globalThis.pt = pt;

export const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnMount: false } } });
const root = createRoot(document.querySelector("#app-root"));
root.render(
  <React.StrictMode>
    <UIComponentsProvider initialColorTheme={"device"}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <Router>
            <AuthenticatedRouter />
          </Router>
        </Provider>
      </QueryClientProvider>
    </UIComponentsProvider>
  </React.StrictMode>,
);
