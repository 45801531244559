import { Tile } from "@darktrace/ui-components";
import React from "react";
import styled from "styled-components";

const StyledTile = styled(Tile)`
  i {
    padding-right: 1.2rem;
    color: var(--dt-ui-button-tertiary-hover-text-color);
  }
`;

export function AnalystTile({ name, onClick = () => {}, active = false, disabled = false }) {
  return (
    <StyledTile noColorBar onClick={onClick} active={active} disabled={disabled}>
      <i className="far fa-user"></i>
      {name}
    </StyledTile>
  );
}
