import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useDeleteClientSAMLProviderProfileMutation } from "../../logic/api.js";
import { ClickAwayListener, DropdownDropper, IconButton, Pill, Table, TableWrapper } from "@darktrace/ui-components";
import { SAMLRoleMappingsModal } from "./SAMLRolesCard.jsx";
import { Confirmation } from "../shared/Confirmation.jsx";
import { SAMLGroupMappingsModal } from "./SAMLGroupsCard.jsx";

const StyledMappingsTable = styled(Table)`
  .dt-ui-table__th:nth-child(1) *,
  .dt-ui-table__td:nth-child(1) * {
    white-space: nowrap;
  }

  .dt-ui-table__th:nth-child(1),
  .dt-ui-table__td:nth-child(1),
  .dt-ui-table__th:nth-child(3),
  .dt-ui-table__td:nth-child(3) {
    width: 0;
  }
`;

export function Mappings({ mappings, type, isLoadingMappings, /* isLoadingProductGroups, */ clientSamlProviderId }) {
  const tableHeaders = [
    { title: t(`SAML Group`) },
    { title: type === "group" ? t(`Darktrace Product Group`) : t(`Darktrace ActiveAI Security Portal Role`) },
    { title: null },
  ];

  const tableData = mappings.map((mapping) => [
    // NAME
    mapping.name,

    // PILLS
    <MappingPills key={`${mapping.profileId}-mapping-pills`} items={mapping.items} />,

    // OPTIONS BUTTON
    <SAMLMappingOptionsButton
      key={`${mapping.profileId}-saml-mapping-options-button`}
      samlGroup={mapping.name}
      profileId={mapping.profileId}
      type={type}
      clientSamlProviderId={clientSamlProviderId}
    />,
  ]);

  return (
    <TableWrapper>
      <StyledMappingsTable className="mappings-table" headers={tableHeaders} data={tableData} loading={isLoadingMappings} />
      {!isLoadingMappings && tableData.length === 0 && <div style={{ width: "100%", padding: "0.8rem" }}>No mappings to show.</div>}
    </TableWrapper>
  );
}

function SAMLMappingOptionsButton({ type, samlGroup, profileId, clientSamlProviderId }) {
  const buttonRef = useRef();
  const [isDropdownOpen, setIsDropdownOpen] = useState();
  const [isEditMappingsModalOpen, setIsEditMappingsModalOpen] = useState(false);
  const [isDeleteMappingsModalOpen, setIsDeleteMappingsModalOpen] = useState(false);

  function handleSelectItem(id) {
    if (id === "edit") setIsEditMappingsModalOpen(true);
    if (id === "delete") setIsDeleteMappingsModalOpen(true);
  }

  const { mutateAsync: deleteSamlProfile } = useDeleteClientSAMLProviderProfileMutation({ profileId });

  const SAMLMappingsModal = type === "group" ? SAMLGroupMappingsModal : SAMLRoleMappingsModal;

  function handleDeleteSamlProfile() {
    deleteSamlProfile().then(() => setIsDeleteMappingsModalOpen(false));
  }

  return (
    <>
      <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
        <IconButton
          key="icon-btn"
          ref={buttonRef}
          variant="secondary"
          icon="ellipsis-vertical"
          onClick={() => setIsDropdownOpen((prev) => !prev)}
        />
      </ClickAwayListener>

      <DropdownDropper
        dropdownArrow
        anchorEl={buttonRef?.current}
        open={isDropdownOpen}
        placement="left"
        onSelect={handleSelectItem}
        items={[
          { id: "edit", icon: "pen", label: "Edit" },
          { id: "delete", icon: "trash", label: "Delete" },
        ]}
      />

      <SAMLMappingsModal
        open={isEditMappingsModalOpen}
        onClose={() => setIsEditMappingsModalOpen(false)}
        profileId={profileId}
        samlGroup={samlGroup}
        clientSamlProviderId={clientSamlProviderId}
      />

      <Confirmation
        title={`Delete SAML Mappings for ${samlGroup}`}
        message={
          <>
            <div>{t(`Are you sure you want to delete this SAML Profile for ${samlGroup}?`)}</div>
            <div>{t(`Note: this will delete all User Role mappings and Product Group mappings for ${samlGroup}`)}</div>
          </>
        }
        open={isDeleteMappingsModalOpen}
        onClose={() => setIsDeleteMappingsModalOpen(false)}
        onConfirm={handleDeleteSamlProfile}
      />
    </>
  );
}

const StyledMappingPills = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
`;

function MappingPills({ items }) {
  return (
    <StyledMappingPills>
      {items.map(({ id, text, scopedText, instanceId, disabled }) => {
        const key = [id, text, ...(scopedText ? [scopedText] : []), ...(instanceId ? [instanceId] : [])].join("-");
        return (
          <Pill
            key={key}
            colorName="glacier"
            icon={scopedText ? "id-card-clip" : null}
            text={text}
            scopedText={scopedText}
            disabled={disabled}
          />
        );
      })}
    </StyledMappingPills>
  );
}
