import React from "react";
import styled from "styled-components";
import { Username } from "../shared/Username.jsx";

const StyledUserInfoSubheader = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;

  .user-icon-wrapper {
    padding: 0.4rem;
    font-size: 1.6rem;
  }

  .user-details-wrapper {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    padding-block: 0.4rem;

    > * {
      line-height: 1.6rem;
    }

    .name {
      color: var(--dt-ui-text-color-muted);
    }
  }
`;

export function UserInfoSubheader({ name, email, isYou }) {
  return (
    <StyledUserInfoSubheader>
      <div className="user-icon-wrapper">
        <i className="fa-user far" />
      </div>
      <Username username={name} email={email} isYou={isYou} />
    </StyledUserInfoSubheader>
  );
}
