import React from "react";
import { Dropdown } from "@darktrace/ui-components";

export function PageSizeDropdown({ pageSize, onSelectPageSize, options = [10, 50, 100, 500] }) {
  const pageSizeDropdownItems = options.map((option) => ({ id: option, label: `${option}`, selected: pageSize === option.id }));

  return (
    <Dropdown
      className="page-size-dropdown"
      label={pt(`Show`, `Display`)}
      displayValue={pageSize}
      onSelect={onSelectPageSize}
      items={pageSizeDropdownItems}
    />
  );
}
