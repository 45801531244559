import React, { useRef } from "react";
import { useState } from "react";
import { getPaginationStats } from "../../logic/util.js";
import { Button, ClickAwayListener, DropdownDropper, Pagination } from "@darktrace/ui-components";
import { NewUserModal } from "./NewUserModal.jsx";
import styled from "styled-components";
import { PageSizeDropdown } from "../PageSizeDropdown.jsx";
import { PaginationStats } from "../PaginationStats.jsx";
import { useActiveClientId, useMutliClientUserAccessFlags } from "../../logic/hooks.js";
import { useSelector } from "react-redux";
import { useUserProfileInfo } from "../../logic/api.js";
import { AssignRolesModal } from "./AssignRolesModal.jsx";
import { AddExistingUserModal } from "./AddExistingUserModal.jsx";

const StyledUsersTableActionBar = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto 1fr;
  padding: 0.8rem 1.6rem;
  gap: 1.6rem;
  width: 100%;
  overflow-x: auto;
  background-color: var(--dt-ui-card-bg);

  .left-side {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    .pagination-stats {
      white-space: nowrap;
    }

    .page-size-dropdown {
      width: 12rem;
    }

    .dt-ui-input {
      min-width: 24rem;
      width: 24rem;
    }
  }
  .right-side {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;

    .action-buttons {
      display: flex;
      gap: 0.8rem;
    }
  }
`;

export function UsersTableActionBar({ isLoading, currentPage, numTotalUsers, pageSize, updateQueryParams, parentClientAvailableRoles }) {
  const defaultClientId = useSelector((state) => state.app.defaultClientId);
  const activeClientId = useActiveClientId();
  const clients = useSelector((state) => state.app.clients);
  const defaultClientLabel = clients.find((client) => client.id === defaultClientId)?.label;
  const isViewingChildClient = defaultClientId !== activeClientId;

  const addUsersButtonRef = useRef(null);
  const [isAddUsersDropdownOpen, setIsAddUsersDropdownOpen] = useState(false);
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
  const [isAssignRolesModalOpen, setIsAssignRolesModalOpen] = useState(false);
  const [createdUser, setCreatedUser] = useState(null);
  const { showingEntriesFrom, showingEntriesTo } = getPaginationStats(currentPage, numTotalUsers, pageSize);

  const { data: multiClientUserAccessFlags } = useMutliClientUserAccessFlags();
  const canUserViewUsersFromDefaultClient = multiClientUserAccessFlags.some(
    ({ clientId, flags }) => clientId === defaultClientId && flags.includes("user-management"),
  );

  function handleCreateNewUser(newUser) {
    setCreatedUser(newUser);
    setIsAssignRolesModalOpen(true);
  }

  return (
    <>
      <StyledUsersTableActionBar className="action-bar">
        <div className="left-side">
          <PageSizeDropdown pageSize={pageSize} numEntries={numTotalUsers} onSelectPageSize={(id) => updateQueryParams({ length: id })} />

          {isLoading ? (
            <span>{t(`Loading...`)}</span>
          ) : (
            <PaginationStats showingEntriesFrom={showingEntriesFrom} showingEntriesTo={showingEntriesTo} numEntries={numTotalUsers} />
          )}
        </div>
        <div className="center">
          <Pagination
            currentPage={currentPage}
            numEntries={numTotalUsers}
            onSelectPage={(pageNum) => updateQueryParams({ page: pageNum })}
            pageSize={pageSize}
          />
        </div>

        <div className="right-side">
          <span className="spacer" />

          <ClickAwayListener onClickAway={() => setIsAddUsersDropdownOpen(false)}>
            <span>
              <Button
                ref={addUsersButtonRef}
                icon="user-plus"
                onClick={() => {
                  if (isViewingChildClient && canUserViewUsersFromDefaultClient) setIsAddUsersDropdownOpen((prev) => !prev);
                  else setIsNewUserModalOpen(true);
                }}
              >
                {isViewingChildClient ? t(`Add User`) : t(`Invite New User`)}
              </Button>

              <AddUserDropdown
                defaultClientLabel={defaultClientLabel}
                activeClientId={activeClientId}
                addUsersButtonRef={addUsersButtonRef}
                isAddUsersDropdownOpen={isAddUsersDropdownOpen}
                isViewingChildClient={isViewingChildClient}
                setIsAddUsersDropdownOpen={setIsAddUsersDropdownOpen}
                setIsNewUserModalOpen={setIsNewUserModalOpen}
                parentClientAvailableRoles={parentClientAvailableRoles}
              />
            </span>
          </ClickAwayListener>
        </div>
      </StyledUsersTableActionBar>

      <NewUserModal open={isNewUserModalOpen} onClose={() => setIsNewUserModalOpen(false)} onCreateNewUser={handleCreateNewUser} />
      <AssignRolesModal
        user={createdUser}
        open={isAssignRolesModalOpen}
        onClose={() => {
          setIsAssignRolesModalOpen(false);
          setCreatedUser(false);
        }}
      />
    </>
  );
}

function AddUserDropdown({
  activeClientId,
  isViewingChildClient,
  addUsersButtonRef,
  defaultClientLabel,
  isAddUsersDropdownOpen,
  setIsAddUsersDropdownOpen,
  setIsNewUserModalOpen,
}) {
  const [selectedUser, setSelectedUser] = useState(null);
  const isAssignRolesModalOpen = !!selectedUser;

  const [isAddExistingUserModalOpen, setIsAddExistingUserModalOpen] = useState(false);

  const addUsersDropdownItems = [
    { id: "invite-new-user", icon: "user-plus", label: t(`Invite New User`) },
    { id: "add-user-from-parent-client", icon: "merge", label: t(`Add User from {0}`, defaultClientLabel) },
  ];

  function handleClickDropdownItem(id) {
    setIsAddUsersDropdownOpen(false);
    if (id === "invite-new-user") setIsNewUserModalOpen(true);
    if (id === "add-user-from-parent-client") setIsAddExistingUserModalOpen(true);
  }

  const { data = {} } = useUserProfileInfo();
  const userProfileInfo = data.user;

  return (
    <>
      <DropdownDropper
        dropdownArrow
        anchorEl={addUsersButtonRef?.current}
        open={isAddUsersDropdownOpen}
        items={addUsersDropdownItems}
        placement="bottom-end"
        onSelect={handleClickDropdownItem}
      />

      <AddExistingUserModal
        open={isAddExistingUserModalOpen}
        onClose={() => setIsAddExistingUserModalOpen(false)}
        activeClientId={activeClientId}
        defaultClientLabel={defaultClientLabel}
        isViewingChildClient={isViewingChildClient}
      />

      <AssignRolesModal
        open={isAssignRolesModalOpen}
        user={selectedUser}
        onClose={() => setSelectedUser(null)}
        isYou={userProfileInfo?.id === selectedUser?.id}
      />
    </>
  );
}
