import React, { useEffect, useState } from "react";
import { Tabs, Card } from "@darktrace/ui-components";
import styled from "styled-components";
import { OverviewTab } from "./Sidebar/OverviewTab.jsx";
import { PTNAlerts } from "./PTNAlerts/PTNAlerts.jsx";
import { useUserOwnRoles } from "../../logic/api.js";
import { useActiveClientId } from "../../logic/hooks.js";
import { useDispatch, useSelector } from "react-redux";
import { setIsSidepanelCollapsed } from "../../logic/store.js";
import { t } from "./../../logic/translator.js";
import { Escalations } from "./Escalations/Escalations.jsx";
import { useLocation, useNavigate } from "react-router-dom";

const StyledSOCDashboard = styled.div`
  display: flex;
  gap: 1.2rem;
  height: 100%;
  .dt-ui-tabs-wrapper {
    flex-shrink: 0;
  }
  #right {
    width: 100%;
    overflow: hidden;
    .dt-ui-card__contents:has(#ptn-alerts) {
      padding: 0;
    }
    .dt-ui-ghost-element {
      height: 100%;
    }
  }
`;

const contentTabs = {
  overview: [
    { id: "ptn-alerts", icon: "warning", title: t(`PTN Alerts`) },
    { id: "escalations", icon: "flag", title: t(`Escalated Alerts`) },
    { id: "tickets", icon: "ticket", title: t(`Tickets`), disabled: true },
  ],
  settings: [{ id: "option1", title: t(`Option`) }],
};

export function SOCDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTabIdSide, setActiveTabIdSide] = useState();
  const [activeTabIdContent, setActiveTabIdContent] = useState();
  const activeClientId = useActiveClientId();
  const { data: userOwnRoles = [] } = useUserOwnRoles({ clientId: activeClientId });
  const isSidepanelCollapsed = useSelector((state) => state.app.isSidepanelCollapsed);

  const tabsSide = [
    {
      id: "overview",
      tooltip: t(`SOC Overview`),
      icon: "radar",
      contentTitle: t(`SOC Overview`),
      content: <OverviewTab />,
      isVisible: true,
    },
    {
      id: "settings",
      tooltip: t(`Admin Settings`),
      icon: "cog",
      disabled: true,
      contentTitle: t(`Admin Settings`),
      isVisible: userOwnRoles.find((role) => role.id === "internal-client-role-cloud-soc-manager"),
    },
  ];

  useEffect(() => {
    const sections = location.pathname.replace("/soc-dashboard", "").split("/");
    const primaryTab = sections[1] || "overview";
    const secondary = sections[2] || "";
    setActiveTabIdSide(primaryTab);
    setActiveTabIdContent(secondary);
  }, [location.pathname]);

  useEffect(() => {
    if (activeTabIdSide === "overview" && !activeTabIdContent) navigate(`overview/ptn-alerts`);
    if (activeTabIdSide === "settings" && !activeTabIdContent) navigate("settings/option1");
  }, [activeTabIdSide]);

  return (
    <StyledSOCDashboard>
      <Tabs
        tabs={tabsSide
          .filter((tab) => tab.isVisible)
          .map(({ id, tooltip, icon, content, contentTitle, disabled }) => ({
            id,
            tooltip,
            icon,
            content,
            contentTitle,
            disabled,
          }))}
        title={t(`SOC Overview`)}
        width="40rem"
        controlled
        activeTabId={activeTabIdSide}
        onSelectTab={(id) => navigate(id)}
        onClickCollapsedToggle={() => dispatch(setIsSidepanelCollapsed(!isSidepanelCollapsed))}
        collapsable
        collapsed={isSidepanelCollapsed}
      />
      <Card
        id="right"
        title={
          <Tabs
            className="tabs-section"
            controlled
            activeTabId={activeTabIdContent}
            onSelectTab={(id) => navigate(`${activeTabIdSide}/${id}`)}
            tabs={contentTabs[activeTabIdSide]}
          />
        }
      >
        {activeTabIdContent === "ptn-alerts" && <PTNAlerts />}
        {activeTabIdContent === "escalations" && <Escalations />}
        {activeTabIdContent === "tickets" && <div>tickets</div>}
        {activeTabIdContent === "option1" && <div>Pending Designs</div>}
      </Card>
    </StyledSOCDashboard>
  );
}
