import { t } from "./../../logic/translator.js";

export const alertStatus = {
  new: { label: t(`Not Assigned`), color: "var(--dt-ui-resolved-200)" },
  inprogress: { label: t(`In Progress`), color: "var(--dt-ui-medium-100)" },
  escalated: { label: t(`Alert Escalated`), color: "var(--dt-ui-critical-300)" },
  resolved: { label: t(`Resolved`), color: "var(--dt-ui-polar-glacier-200)" },
};

export const defaultSOCFilters = {
  statuses: ["new", "inprogress", "escalated"].join(","),
  hostname: null,
  types: null,
  regions: null,
  assignee_id: null,
};

export const isValidHostname = (val) => {
  let pattern = /^[a-z]+[0-9]?-[0-9]+-[0-9]*/;
  return pattern.test(val);
};
