import { Dropdown } from "@darktrace/ui-components";
import React from "react";
import styled from "styled-components";

const StyledClientSelectorSubheader = styled.div`
  width: 100%;
  display: flex;
  justify-content: stretch;

  .client-dropdown {
    flex-grow: 1;
  }
`;

export function ClientSelectorSubheader({ clients: _clients, onSelectClient }) {
  const clients = _clients.map((client) => ({ id: client.id, label: client.label, selected: client.selected }));

  return (
    <StyledClientSelectorSubheader>
      <Dropdown className="client-dropdown" items={clients} onSelect={onSelectClient} hasSearch />
    </StyledClientSelectorSubheader>
  );
}
