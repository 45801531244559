import { configureStore, createSlice } from "@reduxjs/toolkit";
import { defaultSOCFilters } from "../components/soc-dashboard/utils";

const initialState = {
  isUserVerified: undefined,
  defaultClientId: undefined,
  clients: [],
  toasts: [],
  showTimeoutOverlay: false,
  isSidepanelCollapsed: localStorage.getItem("sidepanel-collapsed") === "true",
  SOCFilters: defaultSOCFilters,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsUserVerified: (state, action) => ({ ...state, isUserVerified: action.payload }),
    setDefaultClientId: (state, action) => ({ ...state, defaultClientId: action.payload }),
    setClients: (state, action) => ({ ...state, clients: action.payload }),
    newToast: (state, action) => {
      // don't show error toast when already showing timeout overlay
      if (action.payload.variant === "error" && state.showTimeoutOverlay) return state;
      return { ...state, toasts: [...state.toasts, { delay: 5000, ...action.payload }] };
    },
    setShowTimeoutOverlay: (state, action) => ({ ...state, showTimeoutOverlay: action.payload }),
    setIsSidepanelCollapsed: (state, action) => {
      localStorage.setItem("sidepanel-collapsed", action.payload);
      return { ...state, isSidepanelCollapsed: action.payload };
    },
    setSOCFilters: (state, action) => ({ ...state, SOCFilters: action.payload }),
  },
});

// Action creators are generated for each case reducer function
export const {
  setClients,
  setDefaultClientId,
  setIsUserVerified,
  newToast,
  setShowTimeoutOverlay,
  setIsSidepanelCollapsed,
  setSOCFilters,
} = appSlice.actions;
export const store = configureStore({ reducer: { app: appSlice.reducer } });
