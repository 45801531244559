import React from "react";
import { Dashboard } from "../components/dashboard/Dashboard.jsx";
import { UserManagement } from "../components/user-management/UserManagement.jsx";
import { SSOConfig } from "../components/SSOConfig/SSOConfig.jsx";
import { SOCDashboard } from "../components/soc-dashboard/SOCDashboard.jsx";
import { t } from "./translator.js";
import { showHiddenFeatures } from "./config.js";
import asmIcon from "../../public/dt-assets/icon/4x/dt-icon-attack-surface-management-gradient.png";
import cloudIcon from "../../public/dt-assets/icon/4x/dt-icon-cloud-gradient.png";
import dmarcIconLight from "../../public/dt-assets/icon/4x/dt-icon-email-dmarc-gradient.png";
import dmarcIconDark from "../../public/images/dt-icon-email-dmarc-gradient-dark.png";
import emailIcon from "../../public/dt-assets/icon/4x/dt-icon-email-gradient.png";
import otIcon from "../../public/dt-assets/icon/4x/dt-icon-ot-gradient.png";
import e2eIcon from "../../public/dt-assets/icon/4x/dt-icon-proactive-exposure-management-gradient.png";
import visualiserIcon from "../../public/dt-assets/icon/4x/dt-icon-threat-visualizer-gradient.png";
import customerPortalIcon from "../../public/dt-assets/icon/2x/dt-icon-customer-portal-gradient.png";
import darktraceLogoTextDark from "../../public/dt-assets/logo/1x/dt-logo-black.png";
import darktraceLogoTextLight from "../../public/dt-assets/logo/1x/dt-logo-grey.png";
import { Integrations } from "../components/integrations/Integrations.jsx";

export const applicationPages = [
  { id: "/dashboard", icon: "rectangles-mixed", name: t(`Dashboard`), element: <Dashboard />, fallback: "/login" },
  { id: "/user-management", icon: "users-cog", name: t(`User Management`), element: <UserManagement />, fallback: "/dashboard" },
  { id: "/sso-config", icon: "solid-key-gear fak", name: t(`SSO Configuration`), element: <SSOConfig />, fallback: "/dashboard" },
  ...(showHiddenFeatures
    ? [
        { id: "/soc-dashboard", icon: "radar", name: t(`SOC Dashboard`), element: <SOCDashboard />, fallback: "/dashboard" },
        { id: "/integrations", icon: "object-group", name: t(`Integrations`), element: <Integrations />, fallback: "/dashboard" },
      ]
    : []),
];

export function getApplicationPages({ userAccessFlags }) {
  return applicationPages.map((page) => {
    if (userAccessFlags === undefined) return { ...page, available: undefined };

    let isPageAvailable = false;
    if (page.id === "/dashboard") isPageAvailable = true;
    if (page.id === "/user-management") isPageAvailable = userAccessFlags.includes("user-management");
    if (page.id === "/sso-config") isPageAvailable = userAccessFlags.includes("client-management:single-sign-on");
    if (page.id === "/soc-dashboard") isPageAvailable = showHiddenFeatures && userAccessFlags.includes("cloud-soc");
    if (page.id === "/integrations") isPageAvailable = showHiddenFeatures; // TODO: access flag needs adding at /api/user/access/flags

    return { ...page, available: isPageAvailable };
  });
}

export const registrationStatuses = {
  synced: { text: t(`Synced`), icon: "rotate far" },
  completed: { text: t(`Registration Complete`), icon: "circle-check far" },
  pending: { text: t(`Registration Pending`), icon: "clock far" },
  expired: { text: t(`Registration Expired`), icon: "circle-exclamation far" },
  error: { text: t(`Registration Error`), icon: "circle-exclamation far" },
  disabled: { text: t(`User Disabled`), icon: "circle-xmark far" },
};

export function getPaginationStats(currentPage = 1, numEntries = 0, pageSize = Math.max(numEntries, 1)) {
  // validate currentPage
  if (!Number.isInteger(currentPage)) throw new Error(`"currentPage" must be an integer (currently ${typeof currentPage}:${currentPage})`);
  if (currentPage < 1) throw new Error(`"currentPage" must be greater than or equal to 1 (currently ${currentPage})`);

  // validate numEntries
  if (!Number.isInteger(numEntries)) throw new Error(`"numEntries" must be an integer (currently ${typeof numEntries}:${numEntries})`);
  if (numEntries < 0) throw new Error(`"numEntries" must be greater than or equal to 0 (currently ${numEntries})`);

  // validate pageSize
  if (!Number.isInteger(pageSize)) throw new Error(`"pageSize"  must be an integer (currently ${typeof pageSize}:${pageSize})`);
  if (pageSize < 1) throw new Error(`"pageSize" must be greater than or equal to 1 (currently ${pageSize})`);

  const showingEntriesFrom = Math.min(numEntries, (currentPage - 1) * pageSize + 1);
  const showingEntriesTo = Math.min(numEntries, (currentPage - 1) * pageSize + pageSize);
  return { showingEntriesFrom, showingEntriesTo };
}

export const PRODUCT_DESCRIPTIONS = {
  "sabre-otrm": t(
    `The industry's first and only trusted AI autonomous response for OT and critical infrastructure, Darktrace/OT allows security and production to predetermine permitted actions for potential attacks, initiating precise response to stop and contain a threat while ensuring production continues without disruption, every time.`,
  ),
  "identity": t(
    `Respond with precision at machine speed. Stop account takeover, insider threats, and data exfiltration in seconds with Autonomous Response. Darktrace minimizes business disruption by responding autonomously to identity attacks.`,
  ),
  "sabre-e2e": t(
    `Darktrace /Proactive Exposure Management prevents cyber-attacks before they occur, with a deep understanding of your business using AI for comprehensive risk scoring, continuous threat modeling that shows your risks in the practical context of an attack and threat and vulnerability management with the context needed to secure your most vital systems.`,
  ),
  "incidentReadinessAndRecovery": t(
    `Darktrace /Incident Readiness & Recovery is an AI engine that learns your data to determine the most effective path to cyber recovery. It trains responders with custom simulations, hardens your deployment against repeat or similar offenses and all while providing a continual assessment of human and technology readiness.`,
  ),
  "asm-service-type": t(
    `Darktrace /Attack Surface Management leverages AI and machine learning to deliver continuous, customized detection of externally exposed assets. This enables you to identify, prevent, and remediate digital risks, ensuring robust, proactive protection against potential threats and vulnerabilities.`,
  ),
  "sabre-email-console": t(
    `Darktrace /EMAIL revolutionizes email security with pioneering AI that blocks threats up to 13 days faster than competitors. Its Self-Learning AI adapts to your unique communication patterns, stopping known and unknown threats without updates. Correlating suspicious behavior across all messaging communications, beyond your inbox, into a unified security platform.`,
  ),
  "network": t(
    `Traditional NDR solutions rely on historical attack data, making them blind to novel threats. Darktrace /NETWORK uses Self-Learning AI to learn what is normal behavior for your organization, detects any activity that could cause business disruption, and autonomously responds to both known and previously unseen threats in real time.`,
  ),
  "sabre-cloud-security": t(
    `As organizations migrate more workloads to the cloud, security teams struggle with maintaining visibility, responding to threats in real-time and enforcing security posture. Darktrace/Cloud is an intelligent cloud security solution powered by Self-Learning AI that delivers continuous, context-aware visibility and monitoring of cloud assets to unlock real-time detection and response, and proactive cloud risk management.`,
  ),
  "endpoint": t(`
    Traditional endpoint solutions are blind to novel threats and suspicious network activity. Darktrace /ENDPOINT works alongside your EDR to learn what is normal behavior for your organization, detecting any network activity on your endpoints that could cause business disruption, and autonomously responding to both known and previously unseen threats in real time.`),
  "dmarc-service-type": "",
  "customer-portal-service-type": "",
  "sabre-service-type": "", //visualizer
  "sabre-threat-visualiser": "", //visualizer
  "ptn-service-type": "",
  "sabre-platforms-accounts-console": "", //saas?
};

export const ROLE_DESCRIPTIONS = {
  "child-role-primary-user": t(
    `The Administrator role on the ActiveAI Security Portal provides user management functionality on a tenant. Users may be added/removed/edited. An Administrator can assign product access to users with the Standard and Administrator roles.`,
  ),
  "child-role-basic-user": t(
    `The Standard User role grants the ability to login to the ActiveAI Security Portal on a tenant. It enables Portal Administrators to assign product access to this user, however Standard role users are not granted product access by default.`,
  ),
  "client-role-primary-user": t(
    `The Administrator role on the ActiveAI Security Portal provides user management functionality on a tenant. Users may be added/removed/edited. An Administrator can assign product access to users with the Standard and Administrator roles.`,
  ),
  "client-role-user-management-admin": t(
    `The User Management Admin role grants the ability to login to the ActiveAI Security Portal on a tenant. It enables the ability to manage the ActiveAI Security Portal roles of other users, manage product access for the user and other users, edit account details for other users, send password reset emails for other users, reset multi-factor authentication (MFA) for other users, and enable/disable other users.`,
  ),
  "client-role-sso-admin": t(
    `The SSO Management Admin role grants the ability to login to the ActiveAI Security Portal on a tenant. It enables the ability to manage the Single Sign On configuration for that tenant. It does not permit product assignment.`,
  ),
};

export const PRODUCT_INFO_MAP = {
  // standard welcome products
  "dmarc-service-type": { logoLight: dmarcIconDark, logoDark: dmarcIconLight, name: "/ EMAIL - DMARC" },
  "asm-service-type": { logoLight: asmIcon, logoDark: asmIcon, name: "/ Attack Surface Management" },
  "customer-portal-service-type": { logoLight: customerPortalIcon, logoDark: customerPortalIcon, name: "Customer Portal" },
  "sabre-service-type": { logoLight: visualiserIcon, logoDark: visualiserIcon, name: "/ Threat Visualizer" },
  "ptn-service-type": { logoLight: darktraceLogoTextDark, logoDark: darktraceLogoTextLight, name: "/ PTN" }, // TODO: update logo

  // sabre products
  "sabre-threat-visualiser": { logoLight: visualiserIcon, logoDark: visualiserIcon, name: "/ Threat Visualizer" },
  "sabre-email-console": { logoLight: emailIcon, logoDark: emailIcon, name: "/ EMAIL" },
  "sabre-platforms-accounts-console": { logoLight: visualiserIcon, logoDark: visualiserIcon, name: "/ Threat Visualizer" }, // TODO: update logo
  "sabre-cloud-security": { logoLight: cloudIcon, logoDark: cloudIcon, name: "/ CLOUD" },
  "sabre-e2e": { logoLight: e2eIcon, logoDark: e2eIcon, name: "/ Proactive Exposure Management" },
  "sabre-otrm": { logoLight: otIcon, logoDark: otIcon, name: "/ OT" },
};
