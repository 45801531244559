export const t = (str, ...args) => {
  if (!args) {
    return str;
  } else {
    let string = str;
    args.forEach((arg, i) => (string = string.replace(`{${i}}`, arg)));
    return string;
  }
};

export const nt = (singular, plural, value) => (value === 1 ? singular : plural);

export const pt = (context, str) => str;
