import { IconButton, Tooltip } from "@darktrace/ui-components";
import React, { useState } from "react";
import styled, { css } from "styled-components";

const StyledCopyInput = styled.div`
  position: relative;
  .dt-ui-input__wrapper {
    width: 100%;
    ${(props) =>
      props.$iscopyable &&
      css`
        > input,
        textarea {
          padding-right: 2.8rem;
        }
      `}
  }
  > button {
    position: absolute;
    top: 0.4rem;
    right: 0.8rem;
  }
`;

export function CopyInput({ isNew, value, Component, ...props }) {
  const [copied, setCopied] = useState(false);

  function handleCopyToClipboard() {
    navigator.clipboard.writeText(value).then(() => setCopied(true));
  }

  return (
    <StyledCopyInput $iscopyable={!isNew}>
      <Component {...props} value={value} />
      {!isNew && (
        <Tooltip text={copied ? "Copied" : "Copy to Clipboard"} onClose={() => setTimeout(() => setCopied(false), 200)}>
          <IconButton icon="copy fal" variant="tertiary" onClick={handleCopyToClipboard} />
        </Tooltip>
      )}
    </StyledCopyInput>
  );
}
